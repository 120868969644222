import React from "react";
import withStyles from '@mui/styles/withStyles';
import NodeTextField from "../../graph/NodeTextField";
import {useProcedureColumnOptions} from "../../../hooks/procedureHooks";
import {useDiagnosticsOn, useNodeOrNull} from "../../../hooks/nodeHooks";
import Typography from "@mui/material/Typography";

const ProcedureCollectionColumnSelector = ({disabled, ruleId, ruleActionType, isDialog, label}) => {
    const options = useProcedureColumnOptions(ruleId, ruleActionType)
    const diagnosticsOn = useDiagnosticsOn()
    const rule = useNodeOrNull(ruleId)
    return (
        <>
            <NodeTextField
                nodeId={ruleId}
                nodePropertyName={'calculateValueQuery'}
                displayLabel={!!label}
                label={label}
                nodeValueOptions={options}
                nodeValueProperty={'message'}
                disabled={disabled}
                usePortalTargetForSelect={isDialog}
            />
            <Typography  variant="caption" display="block" gutterBottom color="errorRed.main">{rule.compileWarnings}</Typography>
            {
                diagnosticsOn &&
                <div>
                    {rule?.calculateValueQuery}
                </div>
            }
        </>
    );
}
const styles = () => ({});
export default withStyles(styles)(ProcedureCollectionColumnSelector);