import React, { useEffect, useRef, useState } from "react";
import { useCallbackPatchNode, useNodeOrNull } from "../../../hooks/nodeHooks";
import { GLOBAL_NAVIGATION_STYLE, NODE_IDS } from "../../../reducers/graphReducer";
import { MINUTES_5 } from "../../../util/constants";
import { strings } from "../../components/SopLocalizedStrings";
import GraphResourceLoad from "../../graph/GraphResourceLoad";
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useTreeViewApiRef } from "@mui/x-tree-view";
import makeStyles from "@mui/styles/makeStyles";
import cn from "classnames";
import { useGlobalNavStyle } from "../../../hooks/procedureHooks";
import ExecutionGlobalNavigationWikiItem from "./ExecutionGlobalNavigationWikiItem";
import { TbfAction, useMobile } from "tbf-react-library";
import CollapseIcon from '@mui/icons-material/KeyboardDoubleArrowLeftRounded';
import ExpandIcon from '@mui/icons-material/KeyboardDoubleArrowRightRounded';

const useStyles = makeStyles((theme) => ({
    container: {
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100vh",
        zIndex: 1,
        boxShadow: `5px 0 7px -5px #d3c2c2`,
        background: "#fff",
        overflowY: "auto",
        overflowX: "hidden",
        transition: "width .3s",
        '&::-webkit-scrollbar': {
            width: 3,
            display: 'block',
        },
        '&::-webkit-scrollbar-track': {
            background: '#FFF',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.grey.four.main,
            borderRadius: 20,
        },
        [theme.breakpoints.up('sm')]: {
            position: "sticky",
            top: 48,
            height: "calc(100vh - 48px)",
            width: "280px",
            zIndex: 20,
        }
    },
    collapsed: {
        width: "48px !important",
    },
    inner: {
        width: "100%",
        padding: theme.spacing(1),
        [theme.breakpoints.up('sm')]: {
            width: "250px",
        },
        [theme.breakpoints.up('md')]: {
            width: "280px",
        },
    },
    header: {
        width: "100%",
        position: "relative",
        padding: '4px 24px 9px 12px',
        borderBottom: `1px solid ${theme.palette.grey.two.main}`,
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        justifyContent: "start",
    },
    heading: {
        lineHeight: 1,
        marginBottom: 0,
        fontFamily: theme.fonts.primary.bold,
        color: theme.palette.grey.seven.main,
    },
    collapseButton: {
        position: "absolute",
        top: `-${theme.spacing(1)}`,
        right: `-${theme.spacing(1)}`,
    },
    expandLabel: {
        position: "absolute",
        inset: 0,
        cursor: "pointer",
    },
    expandButton: {
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: 20,
    },
    tree: {
        width: "100%",
        marginTop: theme.spacing(1),
    }
}));

let currentProcedureId = null;
let currentProcedureName = null;
let expandedItems = [];
export const defaultShowGlobalLink = (tree, mobile) => {
    if (tree) {
        return !mobile;
    }

    return false;
}
export default function ExecutionGlobalNavigation({executionId, preview}) {
    const execution = useNodeOrNull(executionId);
    const mobile = useMobile();
    useEffect(() => {
        if (execution) {
            currentProcedureId = execution.procedureId;
            currentProcedureName = execution.name;
        }
    }, [execution?.procedureId]);
    const linkTree = useNodeOrNull(NODE_IDS.ExecutionLinkTree(execution?.procedureId || currentProcedureId));
    let {showGlobalLinks = defaultShowGlobalLink(linkTree?.tree, mobile)} = useNodeOrNull(NODE_IDS.UserSettings);
    const globalNavStyle = useGlobalNavStyle(execution?.procedureId || currentProcedureId);
    const {id} = useParams();
    const apiRef= useTreeViewApiRef();
    const [expanded, setExpanded] = useState([...new Set([...(linkTree?.ancestryMap?.[id] || []), ...expandedItems])]);
    const patchNode = useCallbackPatchNode();

    const classes = useStyles();

    const localsRef = useRef({initialized: false, itemLinkClicked: null})

    useEffect(() => {
        if (id && linkTree?.ancestryMap && !localsRef.current?.initialized) {
            setExpanded([...new Set([...(linkTree?.ancestryMap?.[id] || []), ...expandedItems, ...expanded])]);
        }
    }, [linkTree?.ancestryMap?.[id]]);

    const handleItemsExpand = (event, expanded) => {
        if (localsRef.current.itemLinkClicked && !expanded.includes(localsRef.current.itemLinkClicked)) {
            expanded.push(localsRef.current.itemLinkClicked);
            localsRef.current.itemLinkClicked = null;
        }
        setExpanded(expanded)
        expandedItems = expanded;
    }

    const handleToggle = () => {
        patchNode({id: NODE_IDS.UserSettings, showGlobalLinks: !showGlobalLinks});
    }

    const handleLinkClick = (e, itemId) => {
        localsRef.current.itemLinkClicked = itemId;
        if (mobile) {
            patchNode({id: NODE_IDS.UserSettings, showGlobalLinks: false})
        }
    }

    if (preview) {
        return null;
    }

    return <>
        {linkTree?.loadUrl && <GraphResourceLoad
            key={linkTree.loadUrl}
            resourcePath={linkTree.loadUrl}
            friendlyName={strings.execution.namePlural}
            nodeType={'ExecutionRoot'}
            reloadIntervalMs={MINUTES_5}
            hideLoader={true}
            incrementalLoadOff={true}
            loadOnPageSizeChange={true}
            displayErrorMode={'none'}
        />}
        {
            linkTree?.tree && globalNavStyle.type === GLOBAL_NAVIGATION_STYLE.wiki.id && <>
                <div className={cn(classes.container, {
                    [classes.collapsed]: !showGlobalLinks,
                })}>
                    {!showGlobalLinks && <>
                        <TbfAction id="expand-global-navigation" className={classes.expandButton} iconComponent={ExpandIcon} onClick={handleToggle} iconButton />
                        <label className={classes.expandLabel} htmlFor="expand-global-navigation" aria-label={strings.execution.show.globalNavigation.expandLabel} />
                    </>}
                    {showGlobalLinks &&<div className={classes.inner}>
                        <div className={classes.header}>
                            <h2 className={classes.heading}>
                                {execution?.name || currentProcedureName}
                            </h2>
                            <TbfAction id="collapse-global-navigation" className={classes.collapseButton} iconComponent={CollapseIcon} onClick={handleToggle} iconButton />
                        </div>
                        <RichTreeView
                            className={classes.tree}
                            apiRef={apiRef}
                            items={linkTree.tree}
                            expandedItems={expanded}
                            slots={{
                                item: ExecutionGlobalNavigationWikiItem,
                            }}
                            slotProps={{
                                item: {
                                    onLinkClick: handleLinkClick,
                                }
                            }}
                            onExpandedItemsChange={handleItemsExpand}
                        />
                    </div>}
                </div>
            </>
        }
    </>;
}