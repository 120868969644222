import React from "react";
import withStyles from '@mui/styles/withStyles';
import ProcedureCollectionColumnSelector from "./ProcedureCollectionColumnSelector";
import NodeTextField from "../../graph/NodeTextField";
import {RULE_ACTION_TYPE} from "../../../reducers/graphReducer";
import GraphDelete from "../../graph/GraphDelete";
import {strings} from "../../components/SopLocalizedStrings";

const ProcedureRuleCollectionOrderBy = ({disabled, ruleId, ruleActionType, standalone, multipleOrderBy, classes, labels = {}}) => {
    ruleActionType = ruleActionType || RULE_ACTION_TYPE.collectionOrder.id;
    const {orderBy, orderByDirection} = labels;

    const component = <tr>
        <td className={classes.column}>
            <ProcedureCollectionColumnSelector
                disabled={disabled}
                ruleId={ruleId}
                rulePropertyName={'calculateValue'}
                ruleActionType={ruleActionType}
            />
        </td>
        <td className={classes.column}>
            <NodeTextField
                nodeId={ruleId}
                nodePropertyName={'orderByDirection'}
                displayLabel={false}
                disabled={disabled}
            />
        </td>
        {
            multipleOrderBy &&
            <td>
                <GraphDelete
                    nodeId={ruleId}
                    iconButton={true}
                    friendlyName={strings.procedure.rule.column}
                    confirmDelete={false}
                    disabled={disabled}
                />
            </td>
        }
    </tr>;

    if (standalone) {
        return <div className={classes.grid}>
            <div>
                <ProcedureCollectionColumnSelector
                    disabled={disabled}
                    ruleId={ruleId}
                    rulePropertyName={'calculateValue'}
                    ruleActionType={ruleActionType}
                    label={orderBy}
                />
            </div>
            <div>
                <NodeTextField
                    nodeId={ruleId}
                    nodePropertyName={'orderByDirection'}
                    displayLabel={!!orderByDirection}
                    disabled={disabled}
                    label={orderByDirection}
                />
            </div>
        </div>;
    }

    return component;
}
const styles = (theme) => ({
    column: {
        verticalAlign: 'top',
    },
    grid: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        columnGap: theme.spacing(1),
        flex: 1,
    }
});
export default withStyles(styles)(ProcedureRuleCollectionOrderBy);