import React, {useCallback} from "react";
import {TbfAction, TbfSelectDeviceFriendly} from "tbf-react-library";
import {GLOBAL_NAVIGATION_STYLE, RULE_ACTION_TYPE} from "../../../reducers/graphReducer";
import {
    useCallbackCreateOrRestoreRule,
} from "../../../hooks/procedureHooks";
import FormLabel from "@mui/material/FormLabel";
import {strings} from "../../components/SopLocalizedStrings";
import ProcedureCompileMessages from "../ProcedureCompileMessages";
import {keyedObjectIdNameToNameLabel} from "../../../util/util";
import { useCallbackPatchNode, useChildRuleByActionOrNull } from "../../../hooks/nodeHooks";
import ProcedureCollectionOrderBy from "../collection/ProcedureCollectionOrderBy";
import CloseIcon from '@mui/icons-material/Close';
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(() => ({
    orderByContainer: {
        display: "flex",
        alignItems: "center",
    },
    orderClear: {
        marginTop: "-10px",
    }
}))

const ProcedureGlobalNavigationStyle = ({disabled, procedureId}) => {
    const rule = useChildRuleByActionOrNull(procedureId, RULE_ACTION_TYPE.globalNavigationStyle.id);

    const cb = useCallbackCreateOrRestoreRule(procedureId, RULE_ACTION_TYPE.globalNavigationStyle.id);

    const classes = useStyles();

    const patchNode = useCallbackPatchNode();

    const optionsByKey = keyedObjectIdNameToNameLabel(GLOBAL_NAVIGATION_STYLE);

    const selectedOption = rule && rule.format && !rule.deleted ? optionsByKey[rule.format] : optionsByKey[GLOBAL_NAVIGATION_STYLE.default.id];

    const handleSelect = useCallback((option) => {
        const on = option.value !== GLOBAL_NAVIGATION_STYLE.default.id;
        cb({on, ruleAttributes: {format: option.value, calculateValueOn: true}});
    }, [cb])

    const handleOrderByClear = () => {
        patchNode({id: rule.id, calculateValueQuery: null,orderByDirection: null});
    }

    return (
        <>
            <div data-cy-element={'Property'}>
                <FormLabel className={'sizeSmall'} htmlFor={'globalNavigationStyle'}>
                    {strings.procedure.settings.design.globalNavigationStyle}
                </FormLabel>
                <TbfSelectDeviceFriendly
                    onChange={handleSelect}
                    propertyName={'globalNavigationStyle'}
                    id={'globalNavigationStyle'}
                    label={'Global navigation style'}
                    options={Object.values(optionsByKey)}
                    value={selectedOption}
                    closeMenuOnSelect={true}
                    isSearchable={false}
                    mobileView={true}
                    menuPortalTarget={document.body}
                    disabled={disabled}
                />
            </div>
            {rule && rule.format !== GLOBAL_NAVIGATION_STYLE.default.id && <div className={classes.orderByContainer}>
                <ProcedureCollectionOrderBy
                    ruleId={rule.id}
                    ruleActionType={RULE_ACTION_TYPE.globalNavigationStyle.id}
                    disabled={disabled}
                    multipleOrderBy={false}
                    labels={{
                        orderBy: strings.procedure.settings.design.globalNavigationStyleOrder,
                        orderByDirection: strings.procedure.settings.design.globalNavigationStyleOrderDirection
                    }}
                    standalone
                />
                {(rule.calculateValueQuery || rule.orderByDirection) &&
                    <TbfAction className={classes.orderClear} iconComponent={CloseIcon} onClick={handleOrderByClear} iconButton />
                }
            </div>}
            {!!rule && <ProcedureCompileMessages nodeId={rule.id}/>}
        </>);
}

export default ProcedureGlobalNavigationStyle;
