import React from "react";
import AssignmentStatus from "../assignments/AssignmentStatus";
import withStyles from '@mui/styles/withStyles';
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import ExecutionThemedIcon from "../execution/ExecutionThemedIcon";

export const WorkItemResultCard = ({classes, data, isCreateOption, optionGrouping, onLinkClick, link}) => {

    const handleLinkClick = (e) => {
        const {value: id} = data;
        onLinkClick?.(e, id);
    }
    
    const Wrapper = link ? ({children}) => <Link to={`/executions/${data.value}`} onClick={handleLinkClick}>
        {children}
    </Link> : React.Fragment;

    return <React.Fragment>
        <label className={classes.customOptionTitle}>
            {
                !isCreateOption && optionGrouping &&
                <ExecutionThemedIcon procedureId={data.procedureId} procedureType={data.procedureType} className={'optionIcon'} alt="Procedure Type Icon" />
            }
            <Wrapper>
                <span className={`executionName option_name ${optionGrouping ? 'typeLink' : ''}`}
                        title={data.label}>{data.label}</span>
            </Wrapper>
            {
                !isCreateOption && optionGrouping &&
                <span className={'dateLabel'}><AssignmentStatus status={data.status}/></span>
            }
        </label>
        {
            data.parentNodes && <label
                className={`customOptionParentName parent_node_options ${!optionGrouping ? 'alignLeft' : ''}`}>{data.parentNodes}</label>
        }
    </React.Fragment>
}

const ExecutionSelectWorkItemOption =
    React.forwardRef(({
                          innerProps,
                          classes,
                          data,
                          isFocused,
                          isSelected,
                          disabled,
                          isCreateOption,
                          optionGrouping,
                          onLinkClick,
                          link
                      }, ref) => {

        return (
            <div ref={ref} {...innerProps}
                 role={'listitem'}
                 className={
                     classes.customOptionContainer +
                     ' select__option' +
                     (isFocused ? ' select__option--is-focused' : '') +
                     (isSelected ? ' select__option--is-selected' : '') +
                     (disabled ? ' select__option--is-disabled' : '')
                 }>
                <WorkItemResultCard classes={classes} data={data} isCreateOption={isCreateOption} onLinkClick={onLinkClick} link={link}

                                    optionGrouping={optionGrouping}/>
            </div>
        )
    });

const styles = theme => ({
    customOptionContainer: {
        width: '100%',
        padding: '7px 15px',
        float: 'left',
        transition: 'background .3s',
        '& .customOptionParentName': {
            width: '100%',
            color: theme.palette.grey.five.main,
            fontSize: 12,
            float: 'left',
            paddingLeft: 30,
            wordBreak: 'break-all',
            display: '-webkit-box',
            '-webkit-line-clamp': 3,
            '-webkit-box-orient': 'vertical',
            overflow: 'hidden',
            '&.alignLeft': {
                paddingLeft: 0,
            }
        },
        '&.select__option--is-selected': {
            '& .customOptionParentName': {
                color: `${theme.palette.primary.two.main} !important`,
            },
            '& .dateLabel .title': {
                color: `${theme.palette.primary.two.main} !important`,
            },
        }
    },
    customOptionTitle: {
        width: '100%',
        float: 'left',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        '& .optionIcon': {
            width: 25,
            height: 25,
            marginRight: 8,
        },
        '& .typeLink': {
            cursor: 'pointer',
            '&:hover': {
                textDecoration: 'underline',
            },
        },
        '& .executionName': {
            paddingRight: 8,
            color: theme.palette.primary.three.main,
            flex: 1,
        },
        '& .dateLabel': {
            fontSize: 12,
            color: theme.palette.grey.five.main,
        },
    },
    grow: {
        flexGrow: 1,
    },
});

export default withStyles(styles)(ExecutionSelectWorkItemOption);
