import React from "react";
import withStyles from '@mui/styles/withStyles';
import cn from 'classnames'
import { useExecutionFullSummaryOrNull, useNodeOrNull } from "../../hooks/nodeHooks";
import TbfSopLink from "./common/TbfSopLink";

const TableCellSpannedLink = ({selectorId, eventName, children, classes, customClass, to, style, expand, ...rest}) => {
  const selector = useNodeOrNull(selectorId);
  const {key, rootId} = rest.row ?? {};
  const execution = useExecutionFullSummaryOrNull(rootId);
  const eventProperties = {
    executionId: rootId,
    executionKey: key,
    procedureId: execution?.procedureId || selector?.procedureId,
    procedureName: execution?.name,
    category: execution?.category,
  }
    return (
      <TbfSopLink
        className={cn('table-cell-spanned-link', classes.fullAreaSpannedLink, customClass, {[classes.expand]: expand})}
        style={style}
        to={to}
        nodeId={selector?.procedureId}
        eventName={eventName}
        eventProperties={eventProperties}
        {...rest}
      >
        {children}
      </TbfSopLink>
    )
}

const styles = () => ({
    fullAreaSpannedLink: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        padding: 16,
        color: 'inherit',
        minHeight: 72,
    },
    expand: {
      width: "fit-content",
      whiteSpace: "pre-wrap",
      maxWidth: 500,
    }
});

export default withStyles(styles)(TableCellSpannedLink);
