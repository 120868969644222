/* eslint-disable jsx-a11y/role-supports-aria-props */
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import withStyles from '@mui/styles/withStyles';
import {getNodeOrError, getNodeOrNull} from "../../selectors/graphSelectors";
import {connect, useDispatch} from "react-redux";
import '../../style/alert.css';
import Button from "@mui/material/Button";
import {putNodeProperty} from "../../actions";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import {formatFullDate, getJsonDate, highlightIncompleteFieldsInForm} from "../../util/util";
import "./../../style/form.css";
import {Loader, SharedAuth} from "tbf-react-library";
import {COMPLETE_ACTION_STYLES, NODE_IDS} from "../../reducers/graphReducer";
import {strings} from "../components/SopLocalizedStrings";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from "@mui/icons-material/NavigateBeforeRounded";
import EditIcon from '@mui/icons-material/Edit';
import {useSaveRunning} from "../../hooks/nodeHooks";
import {useOnline} from "../../hooks/onlineHooks";
import MessageType from "../components/MessageType";
import {useHistory} from "react-router-dom";
import ExecutionCancelReturn from './ExecutionCancelReturn';

const ExecutionCompleteButtonLoader = ({onClick, classes, valid, hasNextStep, hasNextTask, useStrings, completed, navigateNextOnComplete, isCalculator, completeActionStyle}) => {
    const saving = useSaveRunning()
    const isOnline = useOnline({checkRecentErrors: false})
    const anonymous = SharedAuth.isAnonymous()
    const isForm = completeActionStyle === COMPLETE_ACTION_STYLES.form.id;
    const isStandard = completeActionStyle === COMPLETE_ACTION_STYLES.standard.id;

    const formSignoffButtonLabel = () => {
        if (!hasNextTask && !hasNextStep) {
            return useStrings.submitCompleted;
        }
        return <>{completed ? useStrings.nextCompleted : useStrings.completeButton} {navigateNextOnComplete && <NavigateNextIcon/>}</>;
    };

    const standardSignoffButtonLabel = () => {
        if(SharedAuth.isAnonymous() && completed) {
            return useStrings.submitting;
        }

        if(hasNextStep || hasNextTask) {
            return useStrings.completeButton;
        }

        return useStrings.submitCompleted;

    }


    const config = useMemo(() => {
        const buttonConfig = {};

        if (isStandard) {
            buttonConfig.showLoading = anonymous && saving; //completed;
            buttonConfig.content = standardSignoffButtonLabel();
            buttonConfig.disabled = anonymous && completed;
            buttonConfig.title = strings.buttons.signOffAsCompleted;
            buttonConfig.ariaInvalid = !valid;
            buttonConfig.color = valid ? 'primary' : 'inherit';
            buttonConfig.renderButton = true;
        } else {
            buttonConfig.showLoading = saving;
            buttonConfig.content = formSignoffButtonLabel();
            buttonConfig.disabled = completed && !hasNextStep;
            buttonConfig.title = strings.buttons.nextCompleted;
            buttonConfig.ariaInvalid = !valid;
            buttonConfig.renderButton = !completed || anonymous || hasNextStep || isCalculator;

            if(isCalculator && !hasNextTask && !hasNextStep) {
                buttonConfig.color = 'light';
            } else if (!valid) {
                buttonConfig.color = 'inherit';
            }

        }


        return buttonConfig;
    }, [valid, completed, isCalculator, hasNextTask, hasNextStep, completeActionStyle, saving, useStrings])

    if(isForm && completed && !useStrings.nextCompleted) {
        return <></>
    }

    return <React.Fragment>
        {
            config.renderButton &&
            <Button
                onClick={onClick}
                title={config.title}
                type={'submit'}
                variant="contained"
                className={classes.button}
                data-cy='complete'
                color={config.color}
                aria-invalid={config.ariaInvalid}
                disabled={config.disabled}
            >
                {config.content}
                {
                    config.showLoading &&
                    <Loader friendlyName={'Saving'} circular={true} circularSize={16}/>
                }
            </Button>
        }
        {
            !isOnline && completed && anonymous &&
            <MessageType messageType={'warning'}>
                Waiting for internet connection to submit.
            </MessageType>
        }
    </React.Fragment>
}

const ExecutionComplete = ({
                               children,
                               completeEnabled,
                               nextEnabled,
                               nodeId,
                               completed,
                               completedUserName,
                               completedUserEmail,
                               completedDate,
                               classes,
                               canComplete,
                               canCancel,
                               allQuestionsCompleted,
                               hideSignOffAction,
                               userName,
                               userEmail,
                               executionId,
                               nextStepIndex,
                               previousStepIndex,
                               completeActionStyle,
                               nextStepOnComplete,
                               nextTaskIndex,
                               completeLabels,
                               navigateNextOnComplete,
                               isRootCompleted
                           }) => {
    const [attemptedId, setAttemptedId] = useState(null);
    const dispatch = useDispatch();
    const isCalculator = completeActionStyle === COMPLETE_ACTION_STYLES.calculator.id;
    const history = useHistory();

    const handlePreviousStep = useCallback(() => {
        let updatedNode = {
            id: executionId,
            selectedStepIndex: previousStepIndex
        };
        dispatch(putNodeProperty(updatedNode));
    }, [dispatch, previousStepIndex, executionId])

    const handleNextStep = useCallback(() => {
        let updatedNode = {
            id: executionId,
            selectedStepIndex: nextStepIndex
        };
        dispatch(putNodeProperty(updatedNode));
    }, [dispatch, nextStepIndex, executionId])

    const handleSignOff = useCallback((event) => {
        event.preventDefault();
        event.stopPropagation();
        setAttemptedId(nodeId);
        const form = event.target.closest('form');

        if(isCalculator && !nextTaskIndex && !nextStepIndex) {
            history.push('./restart')
            return;
        }

        // Delay signoff as DraftJs + Text are 100ms delayed
        setTimeout(() => {
            if (allQuestionsCompleted) {
                if (nextEnabled) {
                    dispatch(putNodeProperty({id: executionId, selectedStepIndex: nextStepIndex}));
                } else {
                    const now = getJsonDate();
                    const update = {
                        id: nodeId,
                        userCompleted: {
                            completed: true,
                            completedDate: now,
                            completedUserEmail: userEmail,
                            completedUserName: userName,
                            completedUserId: SharedAuth.getUserId()
                        }
                    }
                    dispatch(putNodeProperty(update))
                    if (navigateNextOnComplete && nextStepOnComplete) {
                        handleNextStep()
                    }
                }
            } else {
                highlightIncompleteFieldsInForm(form)
            }
        }, 150)
    }, [allQuestionsCompleted, nodeId, dispatch, nextEnabled, nextStepIndex, nextTaskIndex, handleNextStep, nextStepOnComplete, executionId])

    const handleCancelSignOff = () => {
        let update = {
            id: nodeId,
            userCompleted: null
        }
        dispatch(putNodeProperty(update));
    };

    const attempted = useMemo(() => {
        return attemptedId === nodeId;
    }, [attemptedId, nodeId]);

    if (!completeEnabled && !nextEnabled) {
        return (
            <React.Fragment>
                {children}
            </React.Fragment>
        )
    }

    if (!completed && !canComplete) {
        return (
            <React.Fragment>
                {children}
                {
                    completeLabels.notCompleted &&
                    <Grid container spacing={0} justifyContent={"flex-start"}>
                        <Grid item xs={12}>
                            {completeLabels.notCompleted}
                        </Grid>
                    </Grid>
                }

            </React.Fragment>
        );
    }

    return (
        <form onSubmit={e => e.preventDefault()} className={(attempted ? 'form-attempted' : '') + ' ' + classes.form} aria-readonly={completed}>
            {children}
            {((isRootCompleted && SharedAuth.isAnonymous()) || !completed) && !hideSignOffAction && completeActionStyle === COMPLETE_ACTION_STYLES.standard.id && (
                <Grid container justifyContent={"flx`ex-start"}>
                    <Grid className={classes.signOffOnContainer + ' signOffOn-container'}>
                        <ExecutionCompleteButtonLoader
                            onClick={handleSignOff}
                            classes={classes}
                            valid={allQuestionsCompleted}
                            completed={completed}
                            completeActionStyle={completeActionStyle}
                            hasNextStep={!!nextStepIndex}
                            hasNextTask={!!nextTaskIndex}
                            useStrings={completeLabels}
                        />
                    </Grid>
                </Grid> 
            )}

            {!hideSignOffAction && <Grid className={classes.signOffOnContainer + ' signOffOn-container'} container spacing={0}
                  justifyContent={"flex-start"}>
                <Grid item xs={12}>
                    {completed && completeLabels.nodeCompletedPrefix && (
                        <>
                            {completeLabels.nodeCompletedPrefix} <a href={`mailto:${completedUserEmail}`}
                                                                    title={completedUserEmail}>{completedUserName}</a> on {formatFullDate(completedDate)}
                        </>
                    )}
                    {
                        (canCancel && completeActionStyle === COMPLETE_ACTION_STYLES.standard.id) &&
                        <Button
                            variant={'outlined'}
                            title={completeLabels.undoCompleteButton}
                            color={'secondary'}
                            className={classes.cancelSignOffWithTextButton}
                            onClick={handleCancelSignOff}
                            data-cy='cancel-complete'>
                            {completeLabels.undoCompleteButton}
                        </Button>
                    }
                </Grid>
            </Grid>}
            {!hideSignOffAction && (completeActionStyle === COMPLETE_ACTION_STYLES.form.id || isCalculator) && (
                <Grid container justifyContent="space-between">
                    <Grid item className={classes.signOffOnContainer + ' signOffOn-container'}>
                        {
                                (previousStepIndex !== null && completeLabels.previousCompleted) &&
                            <Button
                                onClick={handlePreviousStep}
                                title={completeLabels.previousCompleted}
                                variant="contained"
                                className={classes.button}
                                data-cy='previous'>
                                {navigateNextOnComplete && <NavigateBeforeIcon/>} {completeLabels.previousCompleted}
                            </Button>
                        }
                        {
                            canCancel &&
                            <Button
                                variant={'contained'}
                                title={completeLabels.undoCompleteButton}
                                className={classes.cancelSignOffButton}
                                onClick={handleCancelSignOff}
                                data-cy='cancel-complete'>
                                <EditIcon/>&nbsp;&nbsp;{completeLabels.undoCompleteButton}
                            </Button>
                        }
                    </Grid>
                    <Grid item className={classes.signOffOnContainer + ' signOffOn-container'}>
                        <ExecutionCancelReturn className={classes.cancelButton} />
                        <ExecutionCompleteButtonLoader
                            onClick={completed ? (nextStepOnComplete ? handleNextStep : null) : handleSignOff}
                            classes={classes}
                            valid={allQuestionsCompleted}
                            hasNextStep={!!nextStepIndex}
                            hasNextTask={!!nextTaskIndex}
                            useStrings={completeLabels}
                            completed={completed}
                            isCalculator={isCalculator}
                            completeActionStyle={completeActionStyle}
                            navigateNextOnComplete={navigateNextOnComplete}
                        />
                    </Grid>
                </Grid>
            )}
        </form>
    );
}

const styles = (theme) => ({
    form: {
        width: '100%'
    },
    button: {
        minWidth: '120px',
        whiteSpace: 'nowrap',
    },
    cancelButton: {
        minWidth: '120px',
        whiteSpace: 'nowrap',
        marginRight: theme.spacing(1),
    },
    cancelSignOffButton: {
        marginLeft: 10
    },
    cancelSignOffWithTextButton: {
        verticalAlign: 'baseline',
        marginLeft: 10
    },
    signOffOnContainer: {
        padding: 10
    },
    loaderContainer: {
        marginLeft: 10
    }
});
ExecutionComplete.propTypes = {
    nodeId: PropTypes.string.isRequired,
};
const mapStateToProps = (state, ownProps) => {
    const user = getNodeOrNull(state, NODE_IDS.User) || {};
    const node = getNodeOrError(state, ownProps.nodeId);
    const rootNode = getNodeOrNull(state, node?.rootId);


    const completeAccess = node.completeAccess;

    return {
        userName: user.name,
        userEmail: user.email,
        ...completeAccess,
        executionId: node.rootId,
        isRootCompleted: rootNode?.completed
    };
};
const mapDispatchToProps = () => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ExecutionComplete));
