import React, { useEffect, useRef, useState } from "react";
import { TbfSelectDeviceFriendly } from "tbf-react-library";
import { strings } from "../../components/SopLocalizedStrings";
import useDebouncedCallback from "../../../hooks/useDebounceCallback";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import GraphResourceLoad from "../../graph/GraphResourceLoad";
import { useGetExecutionSummaryFullByIdIfPresent } from "../../../hooks/nodeHooks";
import Loader from "../../components/Loader";
import { summaryToFullId } from "../../../factory/executionFactory";
import { MINUTES_5 } from "../../../util/constants";

const isObject = (v) => (typeof v == "object" && v !== null);

const listValue = (v, title) => (isObject(v) ? v : {value: v, title: (title !== undefined ? title : v)});

export const mapListValues = (listValues, mapFn) => {
    let ret = [];
    if (Array.isArray(listValues)) {
        for (let v of listValues) {
        const lv = mapFn(listValue(v));
        if (lv != null)
            ret.push(lv);
        }
    } else {
        for (let value in listValues) {
        const lv = mapFn(listValue(value, listValues[value]));
        if (lv != null)
            ret.push(lv);
        }
    }
    return ret;
};

export const mergeListValues = (values, newValues, toStart = false) => {
    if (!newValues)
        return values;
    const old = values || [];
    const newFiltered = newValues.filter(v => old.find(av => av.value == v.value) == undefined);
    const merged = toStart ? [...newFiltered, ...old] : [...old, ...newFiltered];
    return merged;
};

export const listValueToOption = (lv) => {
    if (lv == null) return null;
    const {title, value, disabled, groupTitle, renderTitle} = lv;
    let option = {title, value, label: title};
    if (disabled)
        option.disabled = disabled;
    if (groupTitle)
        option.groupTitle = groupTitle;
    if (renderTitle)
        option.renderTitle = renderTitle;
    return option;
};

export const getListValue = (selectedValue, listValues) => 
    mapListValues(listValues, (lv) => (lv.value === selectedValue ? lv : null))
      .filter(v => v !== null)
      .shift();

const SEARCH_DEBOUNCE_MS = 400;

export default function ProcedureRuleAutocomplete({
    asyncFetch, useLoadMore, useAsyncSearch, forceAsyncSearch,
    asyncListValues: selectedAsyncListValues,
    listValues: staticListValues, allowCustomValues,
    value: selectedValue, setValue, placeholder,
    config, customProps, readonly, type, ...props}) {
    const selectedIds = selectedAsyncListValues?.map(v => v.value || v);
    const selected = useGetExecutionSummaryFullByIdIfPresent(selectedIds);
    const [options, setOptions] = useState(asyncFetch ? [] : staticListValues);
    const [inputValue, setInputValue] = useState("");

    const multiple = type === "multiselect";

    const [loading, setLoading] = useState(false);

    const localsRef = useRef({loadSelected: !!selectedAsyncListValues?.length});

    const handleChange = (option) => {
        localsRef.current.loadSelected = false;
        if (multiple) {
            const selectedOptions = option;
            let newSelectedListValues = selectedOptions.map((o, i) => {
                const item = o.value != null ? o : getListValue(o, options);
                return item;
            });
            let newSelectedValues = newSelectedListValues
                .filter(o => o !== undefined)
                .map(o => (o.value !== undefined ? o.value : o));
            if (!newSelectedValues.length)
                newSelectedValues = undefined; //not allow []
            setValue(newSelectedValues, newSelectedListValues);
        } else {
            const v = option == null ? undefined : option.value;
            setValue(v, v ? [option.value != null ? option : getListValue(option, options)] : []);
        }
    }

    const loadListValues = async (filter = null) => {
        setLoading(true);
        try {
            const {values} = await asyncFetch(filter);
            if (values != null) {
                // Summary data using full id
                setOptions(mergeListValues(values.map(listValueToOption), selected.map(e => ({value: e.id, label: e.title, title: e.title}))));
            }
            setLoading(false);
        } catch {
            setLoading(false);
        }
    };
    const loadListValuesDebounced = useDebouncedCallback(loadListValues, SEARCH_DEBOUNCE_MS, []);

    const handleMenuOpen = () => {
        loadListValues(inputValue);
    }

    const handleSearchTermChange = (value) => {
        const val = value;

        setInputValue(val);

        if (allowCustomValues) {
            if (multiple) {
                //todo
            } else {
                setValue(val, [val]);
            }
        }
        const canSearchAsync = useAsyncSearch && (forceAsyncSearch ? !!val : true);
        if (canSearchAsync) {
            loadListValuesDebounced(val);
        } else if (useAsyncSearch && forceAsyncSearch) {
            setOptions([]);
        }
    }

    const getValueCustomChildren = ({children, data}) => {
        return <Link to={`/executions/${data.value}`}>{children}</Link>
    }

    const ref = useRef();
    const [ruleWidth, setRuleWidth] = useState(null);

    useEffect(() => {
        const containingRule = ref.current?.closest(".rule--body");
        if (containingRule) {
            setRuleWidth(containingRule.clientWidth);
        }
    }, [])

    const { defaultSelectWidth } = config.settings;
    const minWidth = defaultSelectWidth;

    const style = {
        minWidth,
        maxWidth: ruleWidth - 48,
    };

    const useSelectedValue = selectedValue ? [...(Array.isArray(selectedValue) ? selectedValue : [selectedValue])].map((value) => {
        return options?.find(o => o.value === value);
    }) : null;

    const noOptions = () => {
        return strings.execution.search.noOptionsMsg;
    }

    useEffect(() => {
        
        if (localsRef.current.loadSelected) {
            const optionsLoaded = selectedAsyncListValues?.length === selected.length && selected.every(e => e.loaded);
            if (optionsLoaded) {
                // From graph, convert to full id
                setOptions(mergeListValues(options, selected.map(e => ({value: e.rootId || summaryToFullId(e.id), label: e.title, title: e.title}))));
                localsRef.current.loadSelected = false;
            }
        }
    }, [selected]);

    const optionsLoaded = selectedAsyncListValues?.length === selected.length && selected.every(e => e.loaded);

    const selectedWorkItemsUrl = selectedIds ? `/executions?${selectedIds?.map(id => `ids=${id}`).join("&")}&summary=true` : null;
    return <>
        {
            localsRef.current.loadSelected && asyncFetch && selectedWorkItemsUrl &&
            <GraphResourceLoad
                key={selectedWorkItemsUrl}
                resourcePath={selectedWorkItemsUrl}
                friendlyName={strings.execution.namePlural}
                nodeType={'ExecutionRoot'}
                reloadIntervalMs={MINUTES_5}
                displayErrorMode="none"
                hideLoader
                incrementalLoadOff
                hideOfflineWarnings
                skipDeltaLoad
            />
        }
        {
            (localsRef.current.loadSelected && selectedAsyncListValues?.length && !optionsLoaded ?
                <Loader friendlyName={'Selected values'} circular circularSize={24} />
                :
                <div ref={ref} style={{...style}}>
                    <TbfSelectDeviceFriendly
                        value={useSelectedValue}
                        options={options}
                        inputValue={inputValue}
                        onChange={handleChange}
                        handleSearchTermChange={handleSearchTermChange}
                        getValueCustomChildren={getValueCustomChildren}
                        isLoading={loading}
                        placeholder={customProps?.placeholder}
                        multiple={multiple}
                        onMenuOpen={handleMenuOpen}
                        noOptionsMessage={noOptions}
                        menuPortalTarget={document.body}
                        disabled={readonly}
                        isClearable={!multiple}
                    />
                </div>
            )
        }
    </>
}