import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { getNodeOrNull } from "../../selectors/graphSelectors";
import { connect, useDispatch } from "react-redux";
import '../../style/alert.css';
import ExecutionStepsContent from "./ExecutionStepsContent";
import GraphResourceLoad from "../graph/GraphResourceLoad";
import Grid from "@mui/material/Grid";
import ExecutionProperties from "./ExecutionProperties";
import { strings } from "../components/SopLocalizedStrings";
import GraphDeleteMessage from "../graph/GraphDeleteMessage";
import NodeVisited from "../graph/GraphNodeVisited";
import ExecutionShowTop from "./ExecutionShowTop";
import { DIAGNOSTIC_MODES, GLOBAL_NAVIGATION_STYLE, NODE_IDS, NODE_TYPE_OPTIONS } from "../../reducers/graphReducer";
import NodeDestroyedMessage from "../graph/GraphDestroyedMessage";
import {PageContentWrapper, SharedAuth, withMobile} from "tbf-react-library";
import GraphNodeSaveErrorMessage from "../graph/GraphNodeSaveErrorMessage";
import ExecutionDeleteSnackBar from "./ExecutionDeleteSnackBar";
import ExecutionShowDependencies from "./ExecutionShowDependencies";
import { getChildNodeIndex, getNodeContainerByTypeOrNull, isExecutionLoadedFull } from "../../selectors/executionSelectors";
import ExecutionUploadPhotoFromClipboard from "./ExecutionUploadPhotoFromClipboard";
import { isNodeSaved } from "../../factory/executionFactory";
import { ROUTES } from "../Routes";
import { useHistory, withRouter } from "react-router-dom";
import ExecutionRulesList from "./troubleshoot/ExecutionRulesList";
import QueryParamsProvider from './QueryParamsProvider';
import { useCallbackPatchNode, useIsSavePending, useNodeOrNull } from "../../hooks/nodeHooks";
import ExecutionSourceBanner from './ExecutionSourceBanner';
import ExecutionDraftErrorBanner from "./ExecutionDraftErrorBanner";
import {isNullOrUndefined} from "../../util/util";
import ExecutionGlobalNavigation, { defaultShowGlobalLink } from './navigation/ExecutionGlobalNavigation';
import { getGlobalNavStyle } from '../../selectors/procedureSelectors';
import cn from 'classnames';

const useStyles = makeStyles((theme) => ({
    executionStepContainer: {
        position: 'relative',
        paddingTop: theme.spacing(1)
    },
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    addLink: { textDecoration: 'none' },
    questionActionContainer: {
        '& button': {
            float: 'right'
        }
    },
    button: {
        margin: theme.spacing(1),
        minWidth: '120px'
    },
    radio: {
        minWidth: '90px'
    },
    progressIcon: {
        verticalAlign: 'bottom'
    },
    linkButton: {
        color: '#757575',
        marginRight: '10px',
        paddingTop: '5px'
    },
    tabletPadding: {
        [theme.breakpoints.down('sm')]: {
            padding: '8px 25px !important'
        },
    },
    execution: {
        width: "100%",
        transition: "width .3s",
    },
    executionWithNav: {
        width: "100%",
        [theme.breakpoints.up('sm')]: {
            width: "calc(100% - 48px)",
        }
    },
    hasNav: {
        width: "100%",
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - 250px)`,
        },
        [theme.breakpoints.up('md')]: {
            width: `calc(100% - 280px)`,
        },
    },
    page: {
        position: "relative",
        width: "100%",
    },
    withNav: {
        display: "flex",
        width: "calc(100% - 48px)",
        marginLeft: 48,
        [theme.breakpoints.up('sm')]: {
            width: "100%",
            marginLeft: 0,
        }
    }
}));

const ExecutionShowPageRedirect = ({ isAnonymous, returnUrl, executionId }) => {
    const history = useHistory();
    const savePending = useIsSavePending(executionId);
    const execution = useNodeOrNull(executionId);

    useEffect(() => {
        if (isAnonymous && !savePending && execution?.completed && isNodeSaved(execution)) {
            history.replace(ROUTES.executionSubmitted(execution, isAnonymous, returnUrl));
        }
    }, [isAnonymous, savePending, execution, history, returnUrl]);

    return null;
};

const ExecutionShowPage = ({
                               nodeId,
                               executionPreview,
                               executionReady,
                               reloadInterval,
                               loadExecution,
                               renderProperties,
                               isAnonymous,
                               diagnosticsOn,
                               troubleshootOn,
                               executionId,
                               returnUrl,
                               highlightStepIndex,
                               isDraft,
                               procedureId,
                               hasGlobalNav,
                               showGlobalLinks,
                               preview
                           }) => {
    const classes = useStyles();
    const [highlighted, setHighlighted] = useState(null);
    const patch = useCallbackPatchNode();

    useEffect(() => {
        if (patch && !highlighted && highlightStepIndex !== null && highlightStepIndex !== undefined) {
            patch({ id: executionId, selectedStepIndex: highlightStepIndex });
            setHighlighted(true);
        }
    }, [highlightStepIndex, executionId, highlighted, patch]);

    if(isDraft && !isAnonymous) {
        return <ExecutionDraftErrorBanner executionId={executionId} procedureId={procedureId}/>
    }

    return (
        <QueryParamsProvider>
            <div className={cn(classes.page, {[classes.withNav]: hasGlobalNav})}>
                <ExecutionGlobalNavigation executionId={nodeId} preview={preview} />
                <div data-cy={'Execution'} className={cn(classes.execution, {
                    [classes.hasNav]: hasGlobalNav && showGlobalLinks,
                    [classes.executionWithNav]: hasGlobalNav && !showGlobalLinks
                })}>
                    {troubleshootOn && <ExecutionSourceBanner executionId={nodeId} />}
                    {
                        executionReady &&
                        <>
                            <ExecutionShowPageRedirect executionId={executionId} isAnonymous={isAnonymous} returnUrl={returnUrl} />
                            <ExecutionShowTop executionId={executionId} isAnonymous={isAnonymous} />
                        </>
                    }
                    <PageContentWrapper style={{ padding: '0px' }}>
                        {
                            loadExecution &&
                            <GraphResourceLoad
                                resourcePath={`/executions?id=${nodeId}`}
                                friendlyName={strings.execution.name}
                                nodeId={nodeId}
                                nodeType={'ExecutionRoot'}
                                reloadIntervalMs={reloadInterval}
                                disabled={executionPreview === true}
                                hideOfflineWarnings={false}
                            />
                        }
                        <GraphNodeSaveErrorMessage nodeId={nodeId} />
                        {
                            executionReady &&
                            <Grid container>
                                <Grid item xs={12} sm={12} md={renderProperties ? 9 : 12}>
                                    <div className={classes.executionStepContainer}>
                                        <GraphDeleteMessage nodeId={nodeId} />
                                        <NodeDestroyedMessage nodeId={nodeId} />
                                        <ExecutionStepsContent executionId={nodeId} />
                                    </div>
                                </Grid>
                                {
                                    renderProperties ?
                                    <Grid item xs={12} sm={12} md={3} className={classes.tabletPadding}>
                                        <ExecutionProperties executionId={nodeId} />
                                    </Grid> : <div />
                                }
                            </Grid>
                        }
                        <NodeVisited nodeId={nodeId} nodeType={NODE_TYPE_OPTIONS.ExecutionRoot} />
                        <ExecutionShowDependencies executionId={nodeId} />
                        {
                            executionReady && troubleshootOn &&
                            <ExecutionRulesList nodeId={nodeId} diagnosticsOn={diagnosticsOn} />
                        }
                    </PageContentWrapper>
                    <ExecutionDeleteSnackBar />
                    <ExecutionUploadPhotoFromClipboard executionId={nodeId} />
                </div>
            </div>
        </QueryParamsProvider>
    );
};
let lastProcedureId = null;
const mapStateToProps = (state, ownProps) => {
    let nodeId = ownProps.executionId;
    let executionNode = getNodeOrNull(state, nodeId);
    const userDevice = getNodeOrNull(state, NODE_IDS.UserDevice);
    const userSettings = getNodeOrNull(state, NODE_IDS.UserSettings);
    const loadExecution = !executionNode?.loaded || (executionNode && isNodeSaved(executionNode));
    const showExecutionProperties = userSettings.showExecutionProperties;
    const navTree = getNodeOrNull(state, NODE_IDS.ExecutionLinkTree(executionNode?.procedureId || lastProcedureId));
    const showGlobalLinks =  userSettings?.showGlobalLinks ?? defaultShowGlobalLink(navTree?.tree, ownProps.mobileViewPort);
    const renderProperties = !ownProps.dashboard && !ownProps.isAnonymous && isNullOrUndefined(showExecutionProperties) ? true : showExecutionProperties
    let highlightStepIndex = null;
    const globalNavStyle = getGlobalNavStyle(state, lastProcedureId);
    const hasGlobalNav = !ownProps.preview && globalNavStyle && globalNavStyle.deleted !== true && globalNavStyle.type !== GLOBAL_NAVIGATION_STYLE.default.id;
    if (executionNode?.procedureId) {
        lastProcedureId = executionNode.procedureId;
    }
    if (ownProps.highlightId || ownProps.highlightRuleId) {
        const highlightStep = getNodeContainerByTypeOrNull(state, nodeId, {
            nodeId: ownProps.highlightId,
            ruleId: ownProps.highlightRuleId,
            type: NODE_TYPE_OPTIONS.ExecutionStep,
            matchWithProcedure: true,
        });
        highlightStepIndex = getChildNodeIndex(state, executionNode, highlightStep, {visible: !userDevice.troubleshootOn});
    }
    const executionReady = executionNode?.type === NODE_TYPE_OPTIONS.ExecutionRoot && isExecutionLoadedFull(executionNode);
    return {
        nodeId: nodeId,
        executionReady,
        executionPreview: executionNode && executionNode.preview,
        projectNode: executionNode ? getNodeOrNull(state, executionNode.projectId) : null,
        destroyed: executionNode && executionNode.destroyed,
        reloadInterval: executionNode?.reloadInterval,
        loadExecution: loadExecution && !SharedAuth.isAnonymous(),
        renderProperties: renderProperties,
        diagnosticsOn: userDevice.diagnosticMode === DIAGNOSTIC_MODES.full.id,
        troubleshootOn: userDevice.troubleshootOn,
        highlightStepIndex: highlightStepIndex !== -1 ? highlightStepIndex : null,
        isDraft: executionNode?.draft,
        procedureId: executionNode?.procedureId,
        showGlobalLinks,
        hasGlobalNav: executionReady ? hasGlobalNav : showGlobalLinks && !!lastProcedureId,
    };
};

const mapDispatchToProps = () => {
    return {
    };
};

export default withRouter(withMobile()(connect(mapStateToProps, mapDispatchToProps)(ExecutionShowPage)));
