import React from "react";
import {Link, withRouter} from "react-router-dom";
import withStyles from '@mui/styles/withStyles';
import {getProcedureTypeIcon} from "../../util/image";
import {Popover} from "@mui/material";
import AssignmentStatus from "../assignments/AssignmentStatus";
import WorkItemProgressDetails from "./WorkItemProgressDetails";
import Button from "@mui/material/Button";
import {ClickableArea, NewTag, TruncateReadMoreText} from "tbf-react-library";
import {formatDateAge, isNewExecution} from "../../util/util";
import {EXECUTION_SEARCH_VIEWS, NODE_IDS} from "../../reducers/graphReducer";
import {getNodeOrNull} from "../../selectors/graphSelectors";
import {connect} from "react-redux";
import {strings} from "../components/SopLocalizedStrings";
import {calculateFeatureDistanceInKm, formatDistance} from "../../util/location";
import LocationOn from "@mui/icons-material/LocationOn";
import ExecutionThemedIcon from "../execution/ExecutionThemedIcon";

class WorkItemCard extends React.Component {
    state = {
        progressAnchorEl: false
    }

    handleOpenProgressPopover = (event) => {
        event.stopPropagation();
        this.setState({progressAnchorEl: event.target});
    }

    handleCloseProgressPopover = (event) => {
        event.stopPropagation();
        this.setState({progressAnchorEl: null});
    }

    renderOrderedDate = () => {
        const {
            viewId,
            createdDateTime,
            lastUpdatedDateTime,
            completedDate,
            locationFeature,
            workItemFeature,
            isMapPopup
        } = this.props;
        const useViewId = isMapPopup ? 'feature' : viewId;
        switch (useViewId) {
            default:
            case EXECUTION_SEARCH_VIEWS.created.id:
                return `${strings.labels.created} ${formatDateAge(createdDateTime)}`;
            case EXECUTION_SEARCH_VIEWS.assignedToMe.id:
            case EXECUTION_SEARCH_VIEWS.modified.id:
                return `${strings.labels.modified} ${formatDateAge(lastUpdatedDateTime)}`;
            case EXECUTION_SEARCH_VIEWS.completed.id:
                return `${strings.labels.completed} ${formatDateAge(completedDate)}`;
            case 'feature':
                const name = workItemFeature?.properties?.name
                return <React.Fragment>
                    {
                        name &&
                        <React.Fragment>
                            <LocationOn/>
                            {name}
                        </React.Fragment>
                    }

                </React.Fragment>;
            case EXECUTION_SEARCH_VIEWS.nearMe.id:
                let distance = '-';
                if (workItemFeature && locationFeature) {
                    const distanceMeters = calculateFeatureDistanceInKm(locationFeature, workItemFeature)
                    distance = formatDistance(distanceMeters);
                }
                return <React.Fragment>
                    {strings.labels.distance} {distance}
                </React.Fragment>;
        }
    }

    onMouseEnter = (e) => {
      const { onMouseEnter, id } = this.props
      if (onMouseEnter) onMouseEnter(e, id)
    }

    onMouseLeave = (e) => {
      const { onMouseLeave, id } = this.props
      if (onMouseLeave) onMouseLeave(e, id)
    }

    render() {
        const {
            classes,
            procedureType,
            title,
            status,
            totalCount,
            completedCount,
            createdDateTime,
            rootId,
            procedureId
        } = this.props;
        const {progressAnchorEl} = this.state;
        const showProgressPopover = Boolean(progressAnchorEl);

        return <React.Fragment>
            <ClickableArea
              className={classes.workItemCard}
              onMouseEnter={this.onMouseEnter}
              onMouseLeave={this.onMouseLeave}
            >
                <label className={classes.workItemCardHeader}>
                <ExecutionThemedIcon className={'icon'} procedureId={procedureId} procedureType={procedureType} />
                    <Link className={'title'} title={title} to={`/executions/${rootId}`}
                          tabIndex='-1' data-cy={'execution-link'}
                          data-expand-click-area>
                        <TruncateReadMoreText>{title}</TruncateReadMoreText>
                    </Link>
                    {
                        isNewExecution(createdDateTime) &&
                        <span className={classes.newTagWrapper}><NewTag/></span>
                    }
                </label>

                <div className={classes.workItemCardFooter}>
                    <span
                        className={classes.subText}>{this.renderOrderedDate()}</span>
                    <div className={classes.grow}/>
                    <Button onClick={this.handleOpenProgressPopover} className={classes.statusWrapper}
                            title={strings.buttons.showProgressPopover}>
                        <AssignmentStatus status={status}/>
                    </Button>
                </div>
            </ClickableArea>
            {
                showProgressPopover &&
                <Popover
                    open={showProgressPopover}
                    anchorEl={progressAnchorEl}
                    onClose={this.handleCloseProgressPopover}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}>
                    <WorkItemProgressDetails
                        status={status}
                        totalQuestionCount={totalCount}
                        completedQuestionCount={completedCount}
                        handleCloseProgressPopover={this.handleCloseProgressPopover}
                    />
                </Popover>
            }
        </React.Fragment>
    }
}

const styles = theme => ({
    workItemCard: {
        padding: '10px 5px',
        borderBottom: `1px solid ${theme.palette.grey.two.main}`,
        transition: 'box-shadow .2s',
        '&:last-child': {
            borderBottom: `none`,
        },
        '&:hover': {
            boxShadow: '0 3px 3px #00000029',
            background: theme.palette.secondary.one.main
        },
    },
    workItemCardHeader: {
        display: 'flex',
        alignItems: 'center',
        '& .icon': {
            width: 30,
            marginRight: 8,
        },
        '& .title': {
            fontSize: 14,
            color: theme.palette.grey.seven.main,
            fontFamily: theme.fonts.primary.bold,
            flexGrow: 1,
            '&:hover': {
                textDecoration: 'underline',
            },
        }
    },
    workItemCardFooter: {
        display: 'flex',
        alignItems: 'center',
    },
    subText: {
        color: theme.palette.grey.five.main,
        fontSize: 12,
        margin: '8px 0',
        display: 'block',
    },
    fontBold: {
        fontFamily: theme.fonts.primary.bold
    },
    textCapitalise: {
        textTransform: 'capitalize'
    },
    grow: {
        flexGrow: 1
    },
    statusWrapper: {
        padding: '3px 5px',
        borderRadius: 4,
        minWidth: '100px',
        '&:hover': {
            background: theme.palette.grey.one.main,
            '& .title': {
                color: theme.palette.grey.seven.main
            }
        },
    },
    newTagWrapper: {
        display: 'flex',
    }
});

const mapStateToProps = (state, ownProps) => {
    let selector = getNodeOrNull(state, ownProps.selectorId);
    let viewId = (selector && selector.selectedViewId) || null;
    const workItem = getNodeOrNull(state, ownProps.id);
    let location = getNodeOrNull(state, NODE_IDS.Location);
    const workItemFeature = workItem?.feature;

    return {
        viewId: viewId,
        title: workItem.title,
        status: workItem.status,
        procedureType: workItem.procedureType,
        createdDateTime: workItem.createdDateTime,
        lastUpdatedDateTime: workItem.lastUpdatedDateTime,
        completedDate: workItem.completedDate,
        totalCount: workItem.totalQuestionCount + workItem.totalSignoffCount,
        completedCount: workItem.completedQuestionCount + workItem.completedSignoffCount,
        locationFeature: location?.feature,
        workItemFeature: workItemFeature,
        rootId: workItem.rootId,
        procedureId: workItem.procedureId,
    };
};

const mapDispatchToProps = () => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(WorkItemCard)));
