import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';
import {EMPTY_ARRAY, hasItems, hasValue, inArray, isOnline, makeArray, randomString} from "../../util/util";
import {strings} from "./SopLocalizedStrings";
import {connect} from "react-redux";
import {GLOBAL_SEARCH_TYPES} from "../../util/constants";
import {NODE_IDS, NODE_TYPE_OPTIONS} from "../../reducers/graphReducer";
import {getNodeOrNull, getNodesById, getNodeSchemaOrError} from "../../selectors/graphSelectors";
import {createNode} from "../../factory/graphFactory";
import {putNodeProperty, reportNodeEvent} from "../../actions";
import ExecutionSelectWorkItemOption from "./ExecutionSelectWorkItemOption";
import ExecutionSelectTemplateOption from "./ExecutionSelectTemplateOption";
import ExecutionSelectUsersOption from "./ExecutionSelectUsersOption";
import {summaryToFullId} from "../../factory/executionFactory";
import {SharedAuth, TbfSelectDeviceFriendly} from "tbf-react-library";

export const mapExecutionToOption = (executionList, renderOptionKey = true, includeTemplateParent = true, extraData = true) => {
    return executionList ? executionList.map(execution => {
        let parentNodes = null;
        if (hasItems(execution.parents)) {
            execution.parents.forEach(parent => {
                parentNodes = parentNodes ? parentNodes + ' > ' + parent.title : parent.title;
            });
        } else if (includeTemplateParent) {
            parentNodes = execution.name;
        }
        let keyPrefix = renderOptionKey && execution.key ? execution.key + ' - ' : '';
        let data = extraData ? {
            parentNodes: parentNodes,
            procedureType: execution.procedureType,
            procedureId: execution.procedureId,
            type: GLOBAL_SEARCH_TYPES.workItems.id,
            lastUpdatedDateTime: execution.lastUpdatedDateTime,
            status: execution.status,
        } : {};
        return {
            value: execution.rootId || summaryToFullId(execution.id),
            label: keyPrefix + execution.title,
            ...data,
        };
    }) : [];
}

export const mapUserToOption = (executionList) => {
    return executionList ? executionList.map(execution => {
        return {
            value: execution.rootId,
            label: execution.title,
            procedureType: execution.procedureType,
            type: GLOBAL_SEARCH_TYPES.users.id,
            email: execution?.keyField3Value,
        };
    }) : [];
}

export const mapTeamToOption = (teamList) => {
    return teamList ? teamList.map(team => {
        return {
            value: team.id,
            label: team.name,
            auth0Id: team.auth0Id,
            clientId: team.clientId,
            type: GLOBAL_SEARCH_TYPES.teams.id,
        };
    }) : [];
}

export const mapProcedureToOption = (procedureList) => {
    return procedureList ? procedureList.map(procedure => {
        return {
            value: procedure.rootId,
            label: procedure.name,
            procedureId: procedure.rootId,
            procedureType: procedure.procedureType,
            type: GLOBAL_SEARCH_TYPES.templates.id,
            categories: procedure.category ? [procedure.category] : null,
        };
    }) : [];
}

class ExecutionSelect extends Component {
    constructor(props) {
        super(props);
        this.selectRef = props.selectRef || React.createRef();
    }

    componentDidMount() {
        let {selectorSchema, selector, onPutNodeProperty, selectorId} = this.props;
        if (selector == null) {
            let selectorNode = createNode(selectorSchema, {id: selectorId});
            onPutNodeProperty(selectorNode);
        }
    }

    onChange = (value) => {
        let {handleChange} = this.props;
        let convertKvToIdTitle = (item) => {
            return {
                id: item.id || item.value,
                title: item.title || item.label,
                type: item.type,
                procedureType: item.procedureType
            };
        };
        let converted;
        if (value == null) {
            converted = null;
        } else if (Array.isArray(value)) {
            converted = value.map(a => convertKvToIdTitle(a));
        } else {
            converted = convertKvToIdTitle(value);
        }
        handleChange(converted);
    }

    noOptionsMessage = () => {
        const {inputValue, noOptionsAvailable} = this.props;
        return inputValue ?
            isOnline() ?
                noOptionsAvailable ? strings.execution.search.noOptionsMsg : strings.formatString(strings.execution.search.notFoundMsg, inputValue) :
                strings.execution.search.offlineMsg :
            noOptionsAvailable ? strings.execution.search.noOptionsMsg : strings.execution.search.placeholder
    };

    handleSeeAllCountClick = (item) => {
        const {history, inputValue, onPutNodeProperty, selectorId} = this.props;
        if (item.type === GLOBAL_SEARCH_TYPES.workItems.id) {
            onPutNodeProperty({id: selectorId, searchTerm: inputValue});
            history.push(`/list/all`);
        } else if (item.type === GLOBAL_SEARCH_TYPES.templates.id || item.type === GLOBAL_SEARCH_TYPES.users.id || item.type === GLOBAL_SEARCH_TYPES.teams.id) {
            this.showMoreResults(item.type);
        }
        this.selectRef.current.blur();
    }

    showMoreResults = (type) => {
        const {history} = this.props;
        history.push(`/search/allResults/${type}`);
    };

    handleHyperLinkClick = (e, id) => {
        e.stopPropagation();
        const {optionClickedEventName, reportEvent} = this.props;
        if (!e.ctrlKey) {
            this.selectRef.current.blur();
            if (optionClickedEventName) {
                reportEvent(id, optionClickedEventName);
            }
        }
    }

    handleSelectedHyperLinkClick = (e, id) => {
        e.stopPropagation();
        const {selectedClickedEventName, reportEvent} = this.props;
        if (!e.ctrlKey) {
            this.selectRef.current.blur();
            if (selectedClickedEventName) {
                reportEvent(id, selectedClickedEventName);
            }
        }
    }

    getCustomOptionComponent = props => {
        const {optionGrouping, linkOptions} = this.props;
        const {innerProps, innerRef, data, isFocused, isSelected, disabled} = props;
        const isCreateOption = data.label === strings.execution.selectorCreateLinkDialog.createMessage;
        switch (data.type) {
            default:
            case GLOBAL_SEARCH_TYPES.workItems.id:
                return <ExecutionSelectWorkItemOption ref={innerRef} innerProps={innerProps} data={data}
                                                      isFocused={isFocused} isSelected={isSelected}
                                                      disabled={disabled} isCreateOption={isCreateOption}
                                                      link={linkOptions}
                                                      onLinkClick={this.handleHyperLinkClick}
                                                      optionGrouping={optionGrouping}/>;
            case GLOBAL_SEARCH_TYPES.templates.id:
                return <ExecutionSelectTemplateOption ref={innerRef} innerProps={innerProps} data={data}
                                                      isFocused={isFocused} isSelected={isSelected}
                                                      link={linkOptions}
                                                      onLinkClick={this.handleHyperLinkClick}
                                                      disabled={disabled} optionGrouping={optionGrouping}/>;
            case GLOBAL_SEARCH_TYPES.users.id:
            case GLOBAL_SEARCH_TYPES.teams.id:
                return <ExecutionSelectUsersOption ref={innerRef} innerProps={innerProps} data={data}
                                                   isFocused={isFocused} isSelected={isSelected}
                                                   link={linkOptions}
                                                   onLinkClick={this.handleHyperLinkClick}
                                                   disabled={disabled}
                                                   teams={data.type === GLOBAL_SEARCH_TYPES.teams.id}/>;
        }
    };

    getValueCustomChildren = props => {
        const {workItemSearch, isContractorUser, executionLinks} = this.props;
        const {children, data} = props;
        const isHyperLink = (workItemSearch && !isContractorUser) || (workItemSearch && isContractorUser && inArray(executionLinks, data.value));

        if (isHyperLink) {
            const handleSelectedLinkClick = (e) => {
                this.handleSelectedHyperLinkClick(e, data.value);
            }
            return <Link to={`/executions/${data.value}`}
                         onTouchStart={handleSelectedLinkClick}
                         onTouchEnd={handleSelectedLinkClick}
                         onMouseDown={handleSelectedLinkClick}
                         onContextMenu={handleSelectedLinkClick}>{children}</Link>
        }
        return null;
    }

    render() {
        let {
            customId,
            multiple,
            propertyName,
            disabled,
            placeholder,
            classes,
            isFocusedResized,
            autoFocus,
            customClass,
            customMenuClass,
            value,
            required,
            defaultOptions,
            dropDownAsSearch,
            onBlur,
            onFocus,
            inputValue,
            options,
            isLoading,
            renderOptionKey,
            actions,
            canCreate,
            handleCreateOption,
            menuPortalTarget,
            handleSearchTermChange,
            mobileView,
            allowContextMenu
        } = this.props;

        let valueAsArray = makeArray(value);

        let useValueOptions = mapExecutionToOption(valueAsArray, renderOptionKey);

        if (!multiple) {
            useValueOptions = (useValueOptions && useValueOptions[0]) || '';
        }
        let useOptions = options;
        let useDefaultOptions = mapExecutionToOption(defaultOptions, renderOptionKey);
        let usePlaceholder = !disabled ? (!hasValue(inputValue) && !hasValue(value) && placeholder ? placeholder : null) : '';

        const inputId = propertyName || 'react-select-' + randomString();

        return <div className={classes.container}>
            {/* <FormLabel htmlFor={inputId} hidden={true} aria-label={strings.selectBox.defaultPlaceholder}/> */}
            <TbfSelectDeviceFriendly
                ref={this.selectRef}
                value={useValueOptions}
                defaultOptions={useDefaultOptions}
                options={useOptions}
                required={required}
                inputValue={inputValue || ''}
                cacheOptions={false}
                propertyName={propertyName}
                inputId={inputId}
                customId={customId}
                isClearable={!disabled}
                isLoading={isLoading}
                dropDownAsSearch={dropDownAsSearch}
                customClass={customClass}
                customMenuClass={customMenuClass}
                isFocusedResized={isFocusedResized}
                disabled={disabled}
                filterOption={() => true}
                onChange={this.onChange}
                noOptionsMessage={this.noOptionsMessage}
                autoFocus={autoFocus}
                autoBlur={false}
                onBlur={onBlur}
                onFocus={onFocus}
                handleSearchTermChange={handleSearchTermChange}
                canCreate={canCreate}
                mobileView={mobileView}
                handleCreateOption={handleCreateOption}
                createOptionLabel={strings.execution.selectorCreateLinkDialog.createMessage}
                multiple={multiple}
                closeMenuOnSelect={true}
                placeholder={usePlaceholder}
                menuPortalTarget={menuPortalTarget}
                role={'listbox'}
                actions={actions}
                allowContextMenu={allowContextMenu}
                getCustomOptionComponent={this.getCustomOptionComponent}
                getValueCustomChildren={this.getValueCustomChildren}
                handleSeeAllCountClick={this.handleSeeAllCountClick}
            />
        </div>
    }
}

ExecutionSelect.defaultProps = {
    closeMenuOnChange: true,
    dropDownAsSearch: false
};
const styles = () => ({
    container: {
        position: 'relative',
        width: '100%',
    },
});
const mapStateToProps = (state, ownProps) => {
    const selectorId = NODE_IDS.ExecutionListingPage(null, null, null);
    const selector = getNodeOrNull(state, selectorId);
    const executionNode = getNodeOrNull(state, ownProps.executionId);
    const links = executionNode != null ? getNodesById(state, executionNode?.links || []).filter(a => a && a.deleted !== true) : EMPTY_ARRAY;
    const executionLinks = links.length === 0 ? EMPTY_ARRAY : links.map(link => link.toNodeId);
    const isContractorUser = SharedAuth.isContractorUser();

    return {
        selectorId: selectorId,
        selector: selector,
        selectorSchema: getNodeSchemaOrError(state, NODE_TYPE_OPTIONS.ExecutionListPage),
        isContractorUser: isContractorUser,
        executionLinks: executionLinks,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onPutNodeProperty: node => dispatch(putNodeProperty(node)),
        reportEvent: (id, eventName, eventProperties) => dispatch(reportNodeEvent(id, eventName, eventProperties)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(ExecutionSelect)));
