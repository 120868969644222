import {LINK_TYPES, NODE_TYPE_OPTIONS, QUESTION_TYPES, RULE_ACTION_TYPE} from "../reducers/graphReducer";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useCallback, useEffect, useRef} from "react";
import {putNodeProperty, putNodesProperty} from "../actions";
import {
    useChildRuleByActionAndFormatOrNull,
    useChildRuleByActionOrNull,
    useDescendantsAndSelfIfPresent,
    useNodeOrError,
    useNodeOrNull,
    useNodeSchemaOrError
} from "./nodeHooks";
import {strings} from "../layouts/components/SopLocalizedStrings";
import {dynamicSort} from "../util/util";
import cloneDeep from "lodash/cloneDeep";
import {
    createRule,
    createRuleCollectionColumn,
    createRuleCollectionView,
    pruneDeleted
} from "../factory/procedureFactory";
import {
    getAncestorNodesAndSelf,
    getNodeOrError,
    getNodeOrNull,
    getNodesIfPresent,
    getState
} from "../selectors/graphSelectors";
import {createChildNode} from "../factory/graphFactory";
import {Permissions, ROLES, SECURITY_SCOPES} from "../permissions";
import {
    canAddExecution,
    getActiveProcedureQuestions,
    getCompleteActionStyle,
    getGlobalNavStyle,
    getNavigationStyle,
    getNavigationStyleRule,
    getProcedureConfig,
    getRoleHasProcedurePermission,
    hasProcedurePermission,
    isProcedureLoadedFull
} from "../selectors/procedureSelectors";
import {SharedAuth} from "tbf-react-library";
import {getGroups} from "../selectors/groupSelectors";

export const useCallbackCreateCollectionViewRule = (nodeId) => {
    const ruleSchema = useNodeSchemaOrError(NODE_TYPE_OPTIONS.ProcedureRule)
    const dispatch = useDispatch()
    const node = useNodeOrError(nodeId)
    const procedure = useNodeOrError(node?.rootId)
    return useCallback(() => {
        let node = createRuleCollectionView(nodeId, procedure, ruleSchema);
        let procedurePatch = {id: procedure.id, rules: [...procedure.rules, node.id]}
        dispatch(putNodesProperty([node, procedurePatch]))
    }, [dispatch, procedure, ruleSchema, nodeId]);
}

export const useCallbackCreateRuleCollectionColumn = (parentRuleId, ruleActionType) => {
    const ruleSchema = useNodeSchemaOrError(NODE_TYPE_OPTIONS.ProcedureRule)
    const dispatch = useDispatch()
    const parentRule = useNodeOrError(parentRuleId)
    const procedure = useNodeOrError(parentRule.rootId)
    const rules = useRef([]);
    useEffect(() => {
        rules.current = [...procedure?.rules];
    }, [procedure?.rules.length]);
    return useCallback((attrs = {}) => {
        let node = createRuleCollectionColumn(parentRule, procedure, ruleSchema, ruleActionType, attrs);
        let procedurePatch = {id: procedure.id, rules: [...rules.current, node.id]}
        dispatch(putNodesProperty([node, procedurePatch]))
    }, [dispatch, procedure, ruleSchema, ruleActionType, parentRule]);
}

export const useCallbackCreateRule = (parentRuleId, attributes) => {
    const ruleSchema = useNodeSchemaOrError(NODE_TYPE_OPTIONS.ProcedureRule)
    const dispatch = useDispatch()
    const parentRule = useNodeOrError(parentRuleId)
    const procedure = useNodeOrError(parentRule.rootId)
    return useCallback(() => {
        let node = createChildNode(procedure, ruleSchema, {...attributes, nodeIds: [parentRuleId]});
        dispatch(putNodesProperty([node]))
    }, [dispatch, procedure, attributes, parentRuleId, ruleSchema]);
}

export const useCallbackCreateOrRestoreRule = (forNodeId, actionType, childRules, restoreNode = true) => {
    const ruleSchema = useNodeSchemaOrError(NODE_TYPE_OPTIONS.ProcedureRule)
    const dispatch = useDispatch()
    const forNode = useNodeOrError(forNodeId)
    const procedure = useNodeOrError(forNode.rootId)
    const rule = useChildRuleByActionOrNull(forNodeId, actionType)
    return useCallback(({on = null, ruleAttributes = {}}) => {
        if (on == null) {
            on = !(rule && !rule.deleted)
        }
        if (rule && restoreNode) {
            dispatch(putNodesProperty([{id: rule.id, deleted: !on, ...ruleAttributes}]))
        } else if (on || !restoreNode) {
            let newRule = createRule(forNode, procedure, ruleSchema, {actionType: actionType, ...ruleAttributes})
            let newRules = [newRule];
            for (let childRule of childRules || []) {
                let newChildRule = createRule(newRule, procedure, ruleSchema, childRule)
                newRules.push(newChildRule)
            }
            let procedurePatch = {id: procedure.id, rules: [...procedure.rules, ...newRules.map(a => a.id)]}
            newRules.push(procedurePatch)
            dispatch(putNodesProperty(newRules))
        }

    }, [dispatch, procedure, rule, forNode, ruleSchema, actionType, childRules, restoreNode]);
}

export const useCallbackCreateOrRestoreRuleByFormat = (forNodeId, actionType, format, childRules, restoreNode = true) => {
    const ruleSchema = useNodeSchemaOrError(NODE_TYPE_OPTIONS.ProcedureRule)
    const dispatch = useDispatch()
    const forNode = useNodeOrError(forNodeId)
    const procedure = useNodeOrError(forNode.rootId)
    const rule = useChildRuleByActionAndFormatOrNull(forNodeId, actionType, format)
    return useCallback(({on = null, ruleAttributes = {}}) => {
        if (on == null) {
            on = !(rule && !rule.deleted)
        }
        if (rule && restoreNode) {
            dispatch(putNodesProperty([{id: rule.id, deleted: !on, ...ruleAttributes}]))
        } else if (on || !restoreNode) {
            let newRule = createRule(forNode, procedure, ruleSchema, {actionType, format, ...ruleAttributes})
            let newRules = [newRule];
            for (let childRule of childRules || []) {
                let newChildRule = createRule(newRule, procedure, ruleSchema, childRule)
                newRules.push(newChildRule)
            }
            let procedurePatch = {id: procedure.id, rules: [...procedure.rules, ...newRules.map(a => a.id)]}
            newRules.push(procedurePatch)
            dispatch(putNodesProperty(newRules))
        }

    }, [dispatch, procedure, rule, forNode, ruleSchema, actionType, format, childRules, restoreNode]);
}

export const useProcedureConfig = (procedureId) => useSelector((state) => {
    return getProcedureConfig(state, procedureId);
});

export const useGlobalNavStyle = (procedureId) => useSelector((state) => {
    return getGlobalNavStyle(state, procedureId);
});

/**
 * Available columns for the listing page are currently the standard columns plus question answers.
 * Later we can add other fields, like step/task signoff.
 * Created/Updated user is currently not storing name, so not great to display.
 * @type {{label: string, value: string}[]}
 */

const standardColumnOptions = [];
export const standardColumns = [
    'title',
    'createdDateTime',
    'lastUpdatedDateTime',
    'category',
    'name',
    'procedureType',
    'completedDate',
    'completedRatio',
    'totalPhotoCount',
    'warningCount',
    'status',
    'key',
    'feature'
];
for (let property of standardColumns) {
    const label = strings.execution.columns[property];
    standardColumnOptions.push({value: `{"var":"procedure_${property}"}`, label: label})
}
standardColumnOptions.sort(dynamicSort('label'))
export const getStandardColumnOptions = (procedureId) => {
    let options = [...cloneDeep(standardColumnOptions)];
    for (let option of options) {
        option.value = option.value.replace('procedure', procedureId);
    }
    return options;
}
export const getProcedureColumnOptions = (procedureId, nodes) => {
    let options = getStandardColumnOptions(procedureId)
    for (let node of nodes.filter(a => a.type === NODE_TYPE_OPTIONS.ProcedureQuestion && a.questionType !== QUESTION_TYPES.photo.id && a.questionType !== QUESTION_TYPES.link.id)) {
        options.push({value: `{"var":"${node.id}_finalValueFormatted"}`, label: node.name})
    }
    return options;
}
export const useProcedureColumnOptions = (columnRuleId, ruleActionType) => {
    const isOrder = ruleActionType === RULE_ACTION_TYPE.collectionOrder.id;
    const isGlobalNavStyle = ruleActionType === RULE_ACTION_TYPE.globalNavigationStyle.id
    const rule = useNodeOrError(columnRuleId)
    let procedureId =  isOrder || isGlobalNavStyle ? rule.rootId : rule.procedureId;
    let columns = [];

    const parentRule = useNodeOrError(rule.nodeIds[0]);

    // if rule is collection order and parent rule is block and linkMatchOn is true
    // then this is an order rule under select question type
    if(isOrder && parentRule.actionType === RULE_ACTION_TYPE.block.id && parentRule.linkMatchOn) {
        // check if select only reference one procedure
        // if so, set it as procedureId to fetch all it's column
        if(parentRule.linkMatchProcedureIds?.length === 1){
            procedureId = parentRule.linkMatchProcedureIds[0];
        }
        // check if select reference multiple procedure
        // if yes, use standard columns
        else if(parentRule.linkMatchProcedureIds?.length > 1){
            columns = cloneDeep(standardColumnOptions);
        }
    }


    let nodes = useDescendantsAndSelfIfPresent(procedureId)
    // if columns are not set, this means that we are not using standard column
    // so, get all columns for procedure
    if(!columns.length) {
        columns = getProcedureColumnOptions(procedureId, nodes);
    }

    return columns;
}

export const getRuleReferenceNameAndIndex = (state, rule) => {
    let name;
    let index;
    const mainId = rule.nodeIds[0];
    const mainNode = getNodeOrNull(state, mainId);
    const ancestors = getAncestorNodesAndSelf(state, mainNode);

    const mainIndex = mainNode.number;

    const parts = [`#${mainIndex}`];

    parts.push(...ancestors?.map(n => n.name));

    name = `${parts.join(": ")}`;
    index = mainIndex - 1;

    return {name, index, id: mainId};
}

export const useProcedureReferences = (procedureId) => {
    const state = useSelector(state => getState(state))

    const procedure = getNodeOrError(state, procedureId)
    const rules = getNodesIfPresent(state, procedure.rules).filter(a => !a.deleted)
    const references = {}
    // Query
    // - Select existing, create new
    // Select
    //  - Select existing, create new
    // Auto Create
    // Copy To
    for (let rule of rules) {
        if (rule.linkToQuestionOn || rule.copyToOn) {
            for (let refId of rule.linkMatchProcedureIds || []) {
                for (let retLink of rule.linkMatchLinkTypes || []) {
                    let refLinkName = LINK_TYPES[retLink].name
                    if (!references[refId]) {
                        references[refId] = {
                            procedureId: refId,
                            procedureName: getNodeOrNull(state, refId)?.name || refId + ' - Not found',
                            linkTypes: [],
                            ruleReferencesByNodeIds: {}
                        }
                    }

                    const {name, index, id} = getRuleReferenceNameAndIndex(state, rule);
                    references[refId].ruleReferencesByNodeIds[id] = references[refId].ruleReferencesByNodeIds[id] ?? {
                        name,
                        index,
                        rules: [],
                    }
                    const pushTo = references[refId].ruleReferencesByNodeIds[id].rules;
                    if (rule.addNewOn) {
                        pushTo.push({name: rule.name, type: 'Add new via link ' + refLinkName})
                    }
                    if (rule.addExistingOn) {
                        pushTo.push({name: rule.name, type: 'Add existing via link ' + refLinkName})
                    }
                    if (rule.copyToOn) {
                        pushTo.push({name: rule.name, type: 'Copy to via link ' + refLinkName})
                    }
                }
            }
        }
        if (rule.createExecutionOn || rule.actionType === RULE_ACTION_TYPE.manuallyAddExecution.id || rule.actionType === RULE_ACTION_TYPE.manuallyAddExecutionBulk.id) {
            const refId = rule.createExecutionProcedureId
            const retLink = rule.createExecutionLinkType
            let refLinkName = LINK_TYPES[retLink]?.name
            if (!references[refId]) {
                references[refId] = {
                    procedureId: rule.createExecutionProcedureId,
                    procedureName: getNodeOrNull(state, refId)?.name || refId + ' - Not found',
                    linkTypes: [],
                    ruleReferencesByNodeIds: {},
                }
            }
            const {name, index, id} = getRuleReferenceNameAndIndex(state, rule);
            references[refId].ruleReferencesByNodeIds[id] = references[refId].ruleReferencesByNodeIds[id] ?? {
                name,
                index,
                rules: [],
            }
            const pushTo = references[refId].ruleReferencesByNodeIds[id].rules;
            let message;
            if (rule.createExecutionOn) {
                message = "Auto-create via link " + refLinkName;
            }
            if (rule.actionType === RULE_ACTION_TYPE.manuallyAddExecution.id) {
                message = "Action via link " + refLinkName;
            }
            if (rule.actionType === RULE_ACTION_TYPE.manuallyAddExecutionBulk.id) {
                message = "Bulk action";
            }
            pushTo.push({name: rule.name, type: message});
        }
    }
    return references;
}
export const useSecurityMemberOptions = () => {
    const groups = useSelector(state => getGroups(state), shallowEqual)
    const hasAlpha = SharedAuth.userHasPermission(Permissions.feature.alpha)
    let roles = Object.values(ROLES)
    let scopes = Object.values(SECURITY_SCOPES).filter(a => !a.alpha || hasAlpha)
    const memberships = [...roles, ...groups, ...scopes]
    return memberships.map(a => ({value: a.id, label: a.name}))
}

export const useHasProcedurePermission = (procedureId, permission) => {
    return useSelector(state => hasProcedurePermission(state, procedureId, permission))
}

export const useCanAdd = (addOptions) => {
    return useSelector(state => canAddExecution(state, addOptions))
}


export const useIsProcedureLoadedFull = (procedureId) => {
    return useSelector(state => procedureId != null && isProcedureLoadedFull(getNodeOrNull(state, procedureId)))
}


export const usePruneProcedure = (procedureId) => {
    return useSelector(state => pruneDeleted(state, procedureId));
}
export const useNavigationStyle = (procedureId) => {
    return useSelector(state => getNavigationStyle(state, procedureId))
}

export const useNavigationStyleRule = (procedureId) => {
    return useSelector(state => getNavigationStyleRule(state, procedureId))
}
export const useCompleteActionStyle = (procedureId) => {
    return useSelector(state => getCompleteActionStyle(state, procedureId))
}

export const useRoleHasProcedurePermission = (procedureId, roleOrGroup, permission) => {
    return useSelector(state => getRoleHasProcedurePermission(state, procedureId, roleOrGroup, permission))
}

export const useCallbackReorderRule = (parentRuleId) => {
    const dispatch = useDispatch()
    const parentRule = useNodeOrError(parentRuleId)
    const procedure = useNodeOrError(parentRule.rootId)
    return useCallback(({sourceId, destinationId}) => {
        const rules = [...procedure.rules];
        const sourceIndex = rules.indexOf(sourceId);
        const destinationIndex = rules.indexOf(destinationId);

        // swap source and destination position
        rules[sourceIndex] = destinationId;
        rules[destinationIndex] = sourceId;

        dispatch(putNodeProperty({id: procedure.id, rules}))
    }, [dispatch, procedure]);
}

export const useProcedureSchemas = (procedureId) => {
    const procedure = useNodeOrNull(procedureId);
    return procedure.schemas ?? [];
}
export const useGetActiveProcedureQuestionOptions = (procedureId) => {
    return useSelector(state => {
        const questions = getActiveProcedureQuestions(state, procedureId);
        return questions.map(question => ({value: question.id, label: question.name}))
    })
}