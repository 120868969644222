import React from "react";
import { useTreeItem2 } from '@mui/x-tree-view/useTreeItem2';
import {
    TreeItem2Content,
    TreeItem2IconContainer,
    TreeItem2GroupTransition,
    TreeItem2Label,
    TreeItem2Root,
    TreeItem2Checkbox,
  } from '@mui/x-tree-view/TreeItem2';
  import { TreeItem2Icon } from '@mui/x-tree-view/TreeItem2Icon';
  import { TreeItem2Provider } from '@mui/x-tree-view/TreeItem2Provider';
  import { TreeItem2DragAndDropOverlay } from '@mui/x-tree-view/TreeItem2DragAndDropOverlay';
import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min";
import makeStyles from "@mui/styles/makeStyles";
import cn from "classnames";

const useStyles = makeStyles((theme) => ({
    link: {
        overflow: "hidden",
        "&:hover": {
            textDecoration: "underline",
        }
    },
    label: {
        overflow: "hidden",
        whiteSpace: "wrap",
    },
    selected: {
        backgroundColor: theme.palette.grey.two.main,
        "& .label": {
            fontFamily: theme.fonts.primary.bold,
        }
    }
}));

const ExecutionGlobalNavigationWikiItem = React.forwardRef(function ExecutionGlobalNavigationWikiItem(props, ref) {
const { id, itemId, label, disabled, children, onLinkClick, ...other } = props;
const {id: executionId} = useParams();
const classes = useStyles();

const {
    getRootProps,
    getContentProps,
    getIconContainerProps,
    getCheckboxProps,
    getLabelProps,
    getGroupTransitionProps,
    getDragAndDropOverlayProps,
    status,
} = useTreeItem2({ id, itemId, children, label, disabled, rootRef: ref });

    const handleLinkClick = (e) => {
        onLinkClick?.(e, itemId);
    }

    return (
        <TreeItem2Provider itemId={itemId}>
            <TreeItem2Root {...getRootProps(other)}>
                <TreeItem2Content className={cn({[classes.selected]: executionId === itemId})} {...getContentProps()}>
                    <TreeItem2IconContainer {...getIconContainerProps()}>
                        <TreeItem2Icon status={status} />
                    </TreeItem2IconContainer>
                    <TreeItem2Checkbox {...getCheckboxProps()} />
                    <Link className={classes.link} to={`/executions/${itemId}`} onClick={handleLinkClick}>
                        <TreeItem2Label className={cn(classes.label, "label")} {...getLabelProps()} />
                    </Link>
                    <TreeItem2DragAndDropOverlay {...getDragAndDropOverlayProps()} />
                </TreeItem2Content>
                {children && <TreeItem2GroupTransition {...getGroupTransitionProps()} />}
            </TreeItem2Root>
        </TreeItem2Provider>
    );
});

export default ExecutionGlobalNavigationWikiItem;