import React from "react";
import {TreeItem} from "@mui/x-tree-view/TreeItem";
import withStyles from '@mui/styles/withStyles';
import AssignmentsUserBadge from "../components/AssignmentsUserBadge";
import {putNodeProperty} from "../../actions";
import {connect} from "react-redux";
import ExecutionStatusWithDetails from "./ExecutionStatusWithDetails";
import StopEventPropagator from "../components/StopEventPropagator";
import {withMobile} from "tbf-react-library";
import cn from "classnames";

class ExecutionTOCItem extends React.Component {

    handleTabChange = (event) => {
        const {mobileViewPort, closeOnSelect} = this.props;
        event.preventDefault();
        event.stopPropagation();
        if (event.type === 'click' || (event.type === 'keyup' && (event.keyCode === 13 || event.keyCode === 32))) {
            let {executionId, onPutNodeProperty, item} = this.props;
            onPutNodeProperty({id: executionId, selectedStepIndex: item.stepIndex});
            const element = document.querySelector(`[data-cy-id="${item.id}"]`);
            if (element) {
                const boundingRect = element.getBoundingClientRect();
                const topPos = item.level === 'step' ? 0 : boundingRect.top - 60 + (document.documentElement.scrollTop || document.body.scrollTop);
                setTimeout(() => window.scrollTo({top: topPos, left: 0, behavior: 'smooth'}), 0);
            }

            if(mobileViewPort && closeOnSelect) {
                onPutNodeProperty({id: executionId, treeViewToggleState: false});
            }
        }
    };

    render() {
        const {classes, item, children, dataCy} = this.props;
        const hasWarning = item.warningCount > 0;

        return <TreeItem
            className={cn(classes.itemWrapper, {[classes.unavailable]: !item.visible})}
            itemId={item.id}
            nodeId={item.id}
            key={item.id}
            data-cy={dataCy}
            onClick={this.handleTabChange}
            onKeyUp={this.handleTabChange}
            tabIndex="0"
            data-level={item.level}
            label={
                <span className={classes.labelRoot}>
                    <span className={classes.labelText}>
                        {item.name}
                    </span>
                    {
                        hasWarning &&
                        <label className={classes.badge}>{item.warningCount}</label>
                    }
                    <span className={classes.statusIcon}>
                        <StopEventPropagator>
                            <ExecutionStatusWithDetails nodeId={item.id}/>
                        </StopEventPropagator>
                    </span>
                    <span className={classes.userWrapper}>
                        <StopEventPropagator>
                            <AssignmentsUserBadge nodeId={item.id}/>
                        </StopEventPropagator>
                    </span>
                </span>
            }>
            {children}
        </TreeItem>
    }

}

const styles = theme => ({
    itemWrapper: {
        fontSize: 14,
        color: theme.palette.grey.seven.main,
        wordBreak: 'break-word',
        '& .MuiTreeItem-label': {
            borderRadius: 4,
            padding: '0 10px',
        },
        '&[data-level="step"]': {
            borderBottom: `1px solid ${theme.palette.grey.one.main}`,
            padding: '6px 0',
            '&:last-child': {
                borderBottom: 0,
            }
        },
    },
    unavailable: {
        backgroundColor: theme.palette.deleted.one.main,
    },
    labelRoot: {
        display: 'flex',
        alignItems: 'center',
        padding: '6px 0',
    },
    labelText: {
        fontWeight: 'inherit',
        flexGrow: 1,
        fontSize: 14,
        color: theme.palette.grey.seven.main,
    },
    statusIcon: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 5,
        '& svg': {
            fontSize: 18,
        }
    },
    badge: {
        fontSize: 10,
        background: theme.palette.status.error.icon,
        color: theme.palette.primary.two.main,
        borderRadius: '50%',
        padding: '1px 5px',
        whiteSpace: 'nowrap',
        marginLeft: 2,
    },
    userWrapper: {
        minWidth: 30,
    }
});


ExecutionTOCItem.propTypes = {};
const mapStateToProps = () => {
    return {};
};
const mapDispatchToProps = (dispatch) => {
    return {
        onPutNodeProperty: node => dispatch(putNodeProperty(node))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withMobile()(withStyles(styles)(ExecutionTOCItem)));
