import {Redirect, Route, Switch, withRouter} from "react-router-dom";
import React, {Component} from 'react';
import ProcedureList from "./procedure/ProcedureList";
import ProcedureShow from "./procedure/ProcedureShow";
import ExecutionShow from "./execution/ExecutionShow";
import ProjectList from "./project/ProjectList";
import ProjectShow from "./project/ProjectShow";
import ProjectAddProcedure from "./project/ProjectAddProcedure";
import ProjectEdit from "./project/ProjectEdit";
import {CombinedPermissions, Permissions} from "../permissions";
import {
    Error,
    ErrorBoundary,
    RedirectRenewPage,
    SHARED_AUTH_INSTANCE,
    SharedAuth,
    tbfLocalStorage,
    URL_CALLBACK,
    URL_LOGIN,
    URL_LOGOUT,
    URL_RETURN,
    URL_RETURNED,
    URL_RETURNED_RENEW
} from "tbf-react-library";
import Logout from "./Logout";
import LoginReturn from "./LoginReturn";
import ProjectNew from "./project/ProjectNew";
import ProcedurePreview from "./procedure/ProcedurePreview";
import ProcedureNew from "./procedure/ProcedureNew";
import GraphDashboard from "./graph/GraphDashboard";
import Dashboard from "./dashboard/Dashboard";
import UserActivity from "./user/UserActivity";
import ExecutionActivity from "./execution/ExecutionActivity";
import ExecutionSearch from "./workItems/ListingPage";
import ProcedureHistory from "./activity/DocumentHistory";
import {DIAGNOSTIC_MODES, NODE_IDS, NODE_TYPE_OPTIONS} from "../reducers/graphReducer";
import LoginWrapper from "./LoginWrapper";
import MobileSearch from "./search/MobileSearch";
import NodeView from "./graph/NodeView";
import Assignments from "./assignments/Assignments";
import NotFoundPage from "./components/NotFoundPage";
import GlobalSearchMoreResults from "./search/GlobalSearchMoreResults";
import LoginCallback from "./login/LoginCallback";
import AddNewExecutionRoute from "./sharing/AddNewExecutionRoute";
import DocumentVersionsPage from "./versions/DocumentVersionsPage";
import SubmittedPage from "./submitted/SubmittedPage";
import {connect} from "react-redux";
import {getNodeOrNull} from "../selectors/graphSelectors";
import {isAbsoluteUrl} from "../util/util";
import ProcedureDashboard from "./dashboard/ProcedureDashboard";
import ExecutionGlobalNavigation from "./execution/navigation/ExecutionGlobalNavigation";

const DEFAULT_URL = '/dashboard';

class SOPRouter extends Component {

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        // render is called twice for some reason per route change resulting in the inner page
        // mounting, unmounting, mounting again. This results in double counting page views.
        return this.props.location.key !== nextProps.location.key;
    }

    static recordRequestedPath(requestedPath) {
        tbfLocalStorage.setItem('perm_loginRequestedUrl', requestedPath);
    }

    static getRequestedPath() {
        let path = tbfLocalStorage.getItem('perm_loginRequestedUrl');
        tbfLocalStorage.removeItem('perm_loginRequestedUrl');
        if (!path || path === URL_LOGIN) {
            path = DEFAULT_URL;
        }
        return path;
    }

    getReturnUrl() {
        const {location} = this.props;
        const returnUrl = new URLSearchParams(location.search).get("returnUrl");
        let useReturnUrl;
        if (!isAbsoluteUrl(returnUrl)) {
            useReturnUrl = returnUrl;
        }
        return useReturnUrl;
    }

    render() {
        const { diagnosticModeOn, location } = this.props;
        // TODO: Move the Private and ExternalRoute out so that they are not remounted on every pass
        // React will see see these as new components on every mount as they are created on every render call, but this may have
        // some unintended consequences so may need to investigate this more 
        
        const Private = ({component: Component, permission, ...rest}) => (
            <Route {...rest} render={(routeProps) => {
                if (SharedAuth.isAuthenticated()) {
                    if (!permission || SharedAuth.userHasPermission(permission)) {
                        return <ErrorBoundary><Component {...routeProps} /></ErrorBoundary>;
                    } else {
                        return <Error {...routeProps}
                                      message={'403 Permission Denied. Please contact your administrator for access. You do not have the permission [' + permission + '].'}/>;
                    }
                }
                let requestedPath = routeProps.location.pathname + (routeProps.location.hash ? '#' + routeProps.location.hash : '');
                SOPRouter.recordRequestedPath(requestedPath);
                return (<Redirect to={{pathname: URL_RETURN}}/>);
            }}/>
        );
        const ExternalRoute = ({path, location}) => (
            <Route path={path} render={() => {
                window.location.href = location.pathname;
                return null;
            }}/>
        );
        return (
            <Switch>
                <Private exact path='/dashboard'
                         component={Dashboard}/>
                <Private exact path='/dashboard/:procedureId/:procedureTitle'
                         component={(props) => <ProcedureDashboard procedureId={props.match.params.procedureId} {...props}/>}/>
                <Private exact path='/assignments'
                         component={Assignments}/>
                <Private exact path='/search/mobile'
                         component={MobileSearch}/>
                <Private exact path='/search/allResults/:resultType'
                         component={(props) => <GlobalSearchMoreResults value={this.props.value}
                                                                        resultType={props.match.params.resultType}/>}/>
                <Private exact path='/workspaces/list'
                         component={() => <Redirect to={'/list/workspace'}/>}/>
                <Private exact path='/list/:procedureType'
                         component={(props) => <ExecutionSearch value={this.props.value}
                                                                procedureType={props.match.params.procedureType}/>}/>
                <Private exact
                         path='/list/:procedureType/:procedureId'
                         component={(props) => <ExecutionSearch value={this.props.value}
                                                                procedureType={props.match.params.procedureType}
                                                                procedureId={props.match.params.procedureId}
                                                                viewId={new URLSearchParams(props.location.search).get("defaultView")}
                                                                pivot={new URLSearchParams(props.location.search).get("pivot") == true || new URLSearchParams(props.location.search).get("pivot") == 'true'}/>}/>
                <Private
                    exact
                    path='/graph/dashboard'
                    component={GraphDashboard}
                />
                {<Route exact path='/graph/nodes' component={NodeView}/>}
                {<Route exact path='/graph/nodes/:id' component={(props) => <NodeView nodeId={props.match.params.id}/>}
                />}
                <Route exact path={URL_CALLBACK} component={() => {
                    if (SharedAuth.isAuthenticated()) {
                        let path = SOPRouter.getRequestedPath();
                        return (<Redirect to={path}/>);
                    }
                    return <LoginCallback/>;
                }}/>
                <Route exact path={URL_RETURN} component={() => {
                    if (SharedAuth.isAuthenticated()) {
                        let path = SOPRouter.getRequestedPath();
                        return (<Redirect to={path}/>);
                    }
                    return (<LoginReturn auth={SHARED_AUTH_INSTANCE}/>);
                }}/>
                <Route exact path={URL_RETURNED} component={() => {
                    if (SharedAuth.isAuthenticated()) {
                        let path = SOPRouter.getRequestedPath();
                        return (<Redirect to={path}/>);
                    }
                    return (<Redirect to={URL_RETURN}/>);
                }}/>
                <Private exact path='/procedures'
                         component={() => <ProcedureList value={this.props.value}/>}/>
                <Private exact path='/procedures/:procedureType/new'
                         component={(props) => <ProcedureNew value={this.props.value}
                                                             procedureType={props.match.params.procedureType}/>}/>
                <Private exact path='/procedures/:id'
                         component={(props) => <ProcedureShow procedureId={props.match.params.id}/>}/>
                <Private exact path='/procedures/:id/edit'
                         component={(props) => <ProcedureShow procedureId={props.match.params.id}/>}/>
                <Private exact path='/procedures/:id/preview'
                         component={(props) => <ProcedurePreview procedureId={props.match.params.id}
                                                                 fullPage={true}/>}/>
                <Private exact path='/procedures/:id/history'
                         component={(props) => <ProcedureHistory {...props}/>}
                         nodeType={NODE_TYPE_OPTIONS.ProcedureRoot}/>
                <Private exact path='/procedures/:id/versions'
                         component={(props) => <DocumentVersionsPage
                             {...props}
                             nodeId={props.match.params.id}
                             nodeType={NODE_TYPE_OPTIONS.ProcedureRoot}/>}/>
                <Private exact path='/executions/:id'
                         component={(props) => <>
                        <ExecutionShow
                             executionId={props.match.params.id}
                             returnUrl={this.getReturnUrl()}
                             highlightRuleId={new URLSearchParams(props.location.search).get("highlight_rule")}
                             highlightId={new URLSearchParams(props.location.search).get("highlight")}
                         />
                         </>}/>
                <Route exact path='/public/:clientId/:dataTenant/:procedureName/items/:id'
                       component={(props) => <ExecutionShow executionId={props.match.params.id}
                                                            returnUrl={this.getReturnUrl()}/>}/>
                <Private exact path='/executions/:id/activity'
                         component={(props) => <ExecutionActivity {...props}/>}/>
                <Private exact path='/executions/:id/history'
                         component={(props) => <ProcedureHistory {...props}
                                                                 nodeType={NODE_TYPE_OPTIONS.ExecutionRoot}/>}/>
                <Private exact path='/executions/:id/versions'
                         component={(props) => <DocumentVersionsPage
                             {...props} nodeId={props.match.params.id}
                             nodeType={NODE_TYPE_OPTIONS.ExecutionRoot}/>}/>

                <Route exact path='/public/:clientId/:dataTenant/:procedureName/items/:key/submitted'
                       component={(props) => <SubmittedPage executionKey={props.match.params.key} {...props} returnUrl={this.getReturnUrl()} />}/>
                <Private exact
                         path='/:clientId/:dataTenant/sharing/:procedureId/:procedureTitle/new'
                         component={(props) => <AddNewExecutionRoute
                            value={this.props.value}
                            procedureId={props.match.params.procedureId}
                            anonymous={false}
                            clientId={props.match.params.clientId}
                            dataTenant={props.match.params.dataTenant}
                            returnUrl={this.getReturnUrl()}
                        />}/>
                <Route exact
                       path='/public/:clientId/:dataTenant/sharing/:procedureId/:procedureTitle/new'
                       component={(props) => <AddNewExecutionRoute
                        value={this.props.value}
                        procedureId={props.match.params.procedureId}
                        anonymous={true}
                        clientId={props.match.params.clientId}
                        dataTenant={props.match.params.dataTenant}
                        returnUrl={this.getReturnUrl()}
                    />}/>

                <Route exact path="/public/:clientId/:dataTenant/sharing/:procedureId/:procedureTitle/restart">
                    <Redirect to='./new'/>
                </Route>

                <Private permission={CombinedPermissions.project.listAny} exact path='/projects'
                         component={() => <ProjectList value={this.props.value}/>}/>
                <Private permission={Permissions.project.create} exact path='/projects/new'
                         component={() => <ProjectNew value={this.props.value}/>}/>
                <Private permission={CombinedPermissions.project.readAny} exact path='/projects/:id'
                         component={(props) => <ProjectShow {...props}/>}/>
                <Private permission={Permissions.project.edit} exact path='/projects/:id/edit'
                         component={(props) => <ProjectEdit {...props}/>}/>
                <Private permission={Permissions.execution.create} exact path='/projects/:id/execution/add'
                         component={(props) => <ProjectAddProcedure {...props}/>}/>
                <Private exact path='/users/me/activity'
                         component={(props) => <UserActivity {...props}/>}/>
                <Route exact path={URL_LOGOUT}
                       component={() => <ErrorBoundary><Logout value={this.props.value}
                                                               auth={SHARED_AUTH_INSTANCE}/></ErrorBoundary>}/>
                <Route exact path={URL_LOGIN} component={() => {
                    if (SharedAuth.isAuthenticated()) {
                        let path = SOPRouter.getRequestedPath();
                        return <Redirect to={path}/>
                    }
                    return <LoginWrapper auth={SHARED_AUTH_INSTANCE}/>;
                }
                }/>

                <Route exact path={URL_RETURNED_RENEW} component={() => {
                    if (SharedAuth.isAuthenticated()) {
                        return <RedirectRenewPage auth={SHARED_AUTH_INSTANCE}/>
                    }
                    return <LoginWrapper auth={SHARED_AUTH_INSTANCE}/>;
                }
                }/>
                <Route exact path='/' render={() => {
                    return (<Redirect to={DEFAULT_URL}/>);
                }}/>
                <ExternalRoute path='/identity'/>
                <ExternalRoute path='/manuals'/>
                <ExternalRoute path='/reporting'/>
                <ExternalRoute path='/integration'/>
                <Route component={() => <NotFoundPage/>}/>
            </Switch>
        )
    }
}

const mapStateToProps = (state) => {
    const diagnosticModeOn = getNodeOrNull(state, NODE_IDS.UserDevice)?.diagnosticMode === DIAGNOSTIC_MODES.full.id;
    return { diagnosticModeOn };
}

export default connect(mapStateToProps)(withRouter(SOPRouter));
