import React from "react";
import withStyles from '@mui/styles/withStyles';
import {useNodeOrNull} from "../../../hooks/nodeHooks";
import {NODE_TYPE_OPTIONS, QUESTION_TYPES, RULE_ACTION_TYPE} from "../../../reducers/graphReducer";
import JSONPretty from "react-json-pretty";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { strings } from "../../components/SopLocalizedStrings";

const actionTypes = Object.values(RULE_ACTION_TYPE);

const linkableNodes = [
    NODE_TYPE_OPTIONS.ExecutionRoot,
    NODE_TYPE_OPTIONS.ExecutionStep,
    NODE_TYPE_OPTIONS.ExecutionTask,
    NODE_TYPE_OPTIONS.ExecutionQuestion,
    NODE_TYPE_OPTIONS.ExecutionRule,
]

const ExecutionJsonLogicVar = ({classes, reference}) => {
    let {nodeId, shortName, property, path} = reference

    const isLinkRef = nodeId === "execution";
    if(isLinkRef) {
        const parts = path.split('_');
        nodeId = parts[parts.length - 2];
        property = parts[parts.length - 1];
    }

    let node = useNodeOrNull(nodeId)
    const rootNode = useNodeOrNull(node?.rootId)
    const location = useLocation();
    if (!node) {
        return <React.Fragment>#{shortName} = Unknown Reference</React.Fragment>
    }
    let type = node.type.replace('Execution', '').replace('Procedure', '')
    const subType = node.questionType ? QUESTION_TYPES[node.questionType] : null
    const value = node[property]

    const getVarLink = () => {

        let link;

        if (rootNode.preview || location.pathname?.includes?.("/procedures")) {
            link = location.pathname;
        } else {
            link = `/executions/${node.rootId}`
        }

        if (node.type === NODE_TYPE_OPTIONS.ExecutionRule) {
            link += `?highlight_rule=${node.id}`;
        } else {
            link += `?highlight=${node.id}`;
        }

        return link;
    }

    const renderVar = () => {

        if (isLinkRef) {
            if(!node) {
                type = `Linked Item | Template Not Loaded`;
            } else if (node.type === NODE_TYPE_OPTIONS.ProcedureRoot) {
                type = `Linked Item | ${type}`;
            } else {
                type = `Linked Item | ${rootNode?.name} | ${type}`;
            }
        }

        const content = <>{type}{subType && <span> {subType.name}</span>}: {node.type === NODE_TYPE_OPTIONS.ExecutionRule ? buildRuleActionType(node) : node?.name}</>
        if (linkableNodes.includes(node.type)) {
            return <Link to={getVarLink()} className={classes.link}>{content}</Link>;
        }
        return content;
    }
    
    const buildRuleActionType = (rule) => {
        let actionTypeSummary = "";
        const activeActionTypes = actionTypes.filter((actionType) => rule[actionType.id] === true);
        for (let i = 0; i < activeActionTypes.length; i++) {
            const actionType = activeActionTypes[i];
            if (i === activeActionTypes.length - 1) {
                actionTypeSummary += actionType.name;
            } else {
                actionTypeSummary += `${actionType.name}, `;
            }
        }
        return actionTypeSummary;
    }
    
    const renderValue = (value) => {
        return <>
            {
                value == null && <pre>null</pre>
            }
            {
                value === false && <span>false</span>
            }
            {
                value === true && <span>true</span>
            }
            {
                value !== null && value !== true && value !== false && <JSONPretty
                    data={typeof value === "object" ? value : JSON.stringify(value)}
                />
            }
        </>;
    }

    return (
        <React.Fragment>
            <div>
                #{shortName} = [{renderVar()}].{property}
            </div>
            {
                isLinkRef === false &&
                renderValue(value)
            }
            {
                node && !node.visible &&
                <>
                    <span>
                        {strings.execution.show.troubleshootMode.calculateNotVisible}
                    </span>
                    {renderValue(node.initialValue)}
                </>
            }
        </React.Fragment>
    );
}
const styles = () => ({
    link: {
        textDecoration: 'underline',
    }
});
export default withStyles(styles)(ExecutionJsonLogicVar);
