import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useLayoutEffect, useMemo, useRef, useState} from "react";
import {useHistory} from "react-router-dom";
import withStyles from '@mui/styles/withStyles';
import {isNewExecution, isNullOrUndefined, parseDate} from "../../../util/util";
import WorkItemStatusWithDetails from "../WorkItemStatusWithDetails";
import StopEventPropagator from "../../components/StopEventPropagator";
import {strings} from "../../components/SopLocalizedStrings";
import DistanceToMe from "./DistanceToMe";
import {Percentage} from "./Percentage";
import {PlainValue} from "./PlainValue";
import {DateAge, Nbsp, NewTag, reportEvent, useWindowSize} from "tbf-react-library";
import precondition from "../../../util/common/precondition";
import {jsonLogicApply} from "tbf-jsonlogic";
import {EXECUTION_FILTER_MODE, EXECUTION_SEARCH_FORMATS, FILTER_BY_EXACT_MODE} from "../../../reducers/graphReducer";
import TableCellSpannedLink from "../../components/TableCellSpannedLink";
import HyperLinkText from "../../components/HyperLinkText";
import {DataGridPremium, GridColumnHeaderTitle, GridRow} from '@mui/x-data-grid-premium'
import cn from 'classnames'
import isEmpty from 'lodash/isEmpty'
import Toolbar from './Toolbar';
import dataGridFilterToJsonLogic from './dataGridFilterToJsonLogic'
import map from 'lodash/map'
import isArray from 'lodash/isArray'
import {LicenseInfo} from '@mui/x-license'
import {useCallbackPatchNode, useNodeOrNull, useReportEventCallback} from "../../../hooks/nodeHooks";
import {
    extendedSelect,
    extendedString,
    worqSelect
} from './WorqFilterOperators';
import useDebouncedCallback from "../../../hooks/useDebounceCallback";
import GridBulkActions from "./bulkActions/GridBulkActions";
import { REPORT_EVENT_TYPES } from "../../../util/constants";
import { Chip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUIX_LICENCE)

const virtualScrollerClass = '.MuiDataGrid-virtualScroller';
const cellClassName = 'MuiDataGrid-cell';

const EmptyContent = () => {
    return <div style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
    alignItems: 'center',
    opacity: 0.8
  }}
  >
    <span>{strings.execution.workItems.emptyMessage}</span>
  </div>
}

const cellComponentMapping = {
    link: ({ value, row }) => <>
        <HyperLinkText>{value}</HyperLinkText>
        {
          row.isNewExecution && <><Nbsp/><Nbsp/><NewTag/></>
        }
    </>,
    percentage: ({ value }) => <Percentage value={value}/>,
    distanceToMe: ({ value }) => <DistanceToMe feature={value}/>,
    status: ({ selectorId, row }) => {
      return <StopEventPropagator element={'inline'}>
        <WorkItemStatusWithDetails id={row.id} selectorId={selectorId} eventName={REPORT_EVENT_TYPES.listingPageStatusDetailsOpened.name} />
      </StopEventPropagator>
    },
    plain: ({ value }) => <PlainValue value={value}/>,
    age: ({ value }) => <DateAge value={value}/>
}

const useStyle = makeStyles((theme) => ({
  header: {
    display: "flex",
    alignItems: "center",
  },
  tag: {
    backgroundColor: theme.palette.primary.one.main,
    color: theme.palette.primary.contrastText,
    height: "16px",
    maxWidth: 108,
    whitespace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    border: "none",
    minWidth: "auto",
    "& span": {
      fontSize : `10px !important`,
    }
  },
  tagsContainer: {
    marginLeft: 8,
    display: "flex",
    flexDirection: "row",
    columnGap: 2,
  },
}));

const HeaderName = ({title, tags, description, width}) => {
  const classes = useStyle();
  let useLabel = tags?.length ? <span className={classes.header}>
    {title}
    <span className={classes.tagsContainer}>
      {tags.map(tag => <Chip className={classes.tag} label={tag} variant="filled" color="primary" size="small" />)}
    </span>
  </span> : title;

  return <GridColumnHeaderTitle label={useLabel} description={description} columnWidth={width} />;
}

const CellRenderer = ({ field, cellComponentType, params, columnName, expand, selectorId, eventName }) => {
  precondition.hasValue(cellComponentType, 'cellComponentType is required');
  const Cell = cellComponentMapping[cellComponentType]
  if (!Cell) {
      throw new Error('Unknown cell component type: ' + cellComponentType)
  }
  const { value, formattedValue, row } = params
  return (
    <TableCellSpannedLink selectorId={selectorId} eventName={eventName} to={`/executions/${row.rootId}`} data-cy={columnName} tabIndex={-1} style={{ padding: 0, position: 'relative' }} expand={expand} field={field} {...params}>
      <Cell selectorId={selectorId} value={formattedValue || value} field={field} row={row} />
    </TableCellSpannedLink>
  )
}

const RowRenderer = (props) => {
  return (
    <GridRow {...props} data-cy-execution-link={props.row.dataCyLink} />
  )
}

// see https://mui.com/x/react-data-grid/column-definition/#column-types for a list of possible types
const mapType = (config) => {
  const { dataType, options, filterByExactMode, procedureIds } = config
  if (!dataType) return 'string'
  const type = dataType.id || dataType
  switch(type) {
    case 'number':
      return 'number'
    case 'date':
      return 'date'
    case 'datetime':
      return 'dateTime'
    case 'time':
      return 'time'
    case 'text':
    case 'select':
        const hasOptions = options?.length || Object.keys(options || {}).length
       if(hasOptions) {
        return 'extendedSelect';
       } else if (procedureIds?.length) {
        return 'worqSelect';
       } else {
        return 'string';
       }
    case 'richText':
    case 'geographic':
    case 'message':
      return filterByExactMode === FILTER_BY_EXACT_MODE.words.id ? 'extendedString' : 'string'
    case 'yesno':
      return 'boolean'
    default:
      return 'string'
  }
}

const getCellComponentType = (columnConfig) => {
  // if (type === 'dateTime' || type === 'date' || type === 'time') return type
  const firstFormat = columnConfig.format
    || (columnConfig.sources && Object.values(columnConfig.sources).find(a => a.format)?.format)
    || { id: EXECUTION_SEARCH_FORMATS.plain.id }
  return firstFormat?.id
}

const getHeaderName = ({title, tags}) => {
  let useTitle = title;
  if (tags?.length) {
    useTitle += " " + tags.map((tag) => `(${tag})`).join(" ");
  }

  return useTitle;
}

const buildColumnsAndRows = (workItems, columnsConfig, filterMode, expand, selectorId, events) => {
    const columns = []
    const columnInitialVisibility = {}
    const autosizeOptions = {
        columns: [],
        includeOutliers: true,
        outliersFactor: 3.0,
        includeHeaders: true,
        expand: true,
    }
    for (let columnConfig of columnsConfig || []) {
        // TODO Support multiple formatters per row
        const columnType = mapType(columnConfig)
        const cellComponentType = getCellComponentType(columnConfig, columnType)
        const {options} = columnConfig

        let column = {
            ...columnConfig,
            headerName: getHeaderName(columnConfig),
            renderHeader: (params) => {
              return <HeaderName title={columnConfig.title} tags={columnConfig.tags} description={params.description} width={params.width} />;
            },
            renderCell: (params) => {
          return <CellRenderer selectorId={selectorId} columnName={columnConfig.title} field={columnConfig.field} cellComponentType={cellComponentType} params={params} expand={expand} eventName={events.itemClicked} />
        },
        filterable: !!columnConfig.filterByExact,
        sortable: !!columnConfig.orderBy,
        type: columnType,
        headerAlign: 'left',
        align: 'left',
        valueOptions: options && (isArray(options) ? options : map(options, (label, value) => ({ label, value }))),
        valueGetter: (_, row) => {
          const value = row[columnConfig.field]
          if(columnType === 'date' || columnType === 'dateTime') {
              // Convert the string dates to javascript dates
              return parseDate(value?.value);
          } else if (columnType === 'boolean') {
            return value?.value === 'true';
          }

          return value?.value;
        },
        valueFormatter: (_, row) => {

          if(!row) {
            return undefined;
          }
          const value = row[columnConfig.field]
          return value?.formattedValue;
        }
      }

      if (!expand) {
        column = {
          ...column,
          flex: 1,
          minWidth: 100,
        }
      }

      // Extended types
      switch (column.type) {
        case "extendedSelect":
          column = {...column, ...extendedSelect};
          break;
        case "worqSelect":
          column = {...column, ...worqSelect};
          break;
        case "extendedString":
          column = {...column, ...extendedString};
          break;
      }

      columnInitialVisibility[columnConfig.field] = !columnConfig.hidden;
        columns.push(column);
        autosizeOptions.columns.push(column.field)
  }

  // Build Rows
  let rows = [];
  let rowNumber = 0;
  for (let workItem of workItems.filter(a => a)) {
      let row = {
          id: workItem.id,
          rootId: workItem.rootId,
          feature: workItem.feature,
          rowNumber: rowNumber,
          isNewExecution: isNewExecution(workItem.createdDateTime),
          dataCyLink: workItem.title || workItem.key,
          key: workItem.key,
      };

      for (let mapColumn of columns) {
          const columnSource = mapColumn?.sources?.[workItem.procedureId];
          const field = mapColumn.field;
          if (workItem[field] !== undefined) {
              row[field] = { value: workItem[field], formattedValue: workItem[field] }
          } else if (columnSource?.jsonLogic) {
              const isClientSideFiltering = filterMode === EXECUTION_FILTER_MODE.client.id;
              const data = {
                  execution: workItem
              }
              const jsonLogic = columnSource.jsonLogic;
              // I parse dates so whe can sort, filter and export them on the client side
              const formattedValue = jsonLogicApply(jsonLogic, data)
              // TODO: I don't think this is the best way to do this but i need the unformatted value from the field so i can export it
              // as well as do the client side filtering and sorting
              const serverSideFilteringOrNotString = !isClientSideFiltering || mapColumn.type !== "string";
              const value = jsonLogic.var && serverSideFilteringOrNotString ? jsonLogicApply({ var: jsonLogic.var.replace('.valueFormatted', '.value') }, data) : formattedValue
              row[field] = { value, formattedValue }
          } else if (columnSource?.questionId) {
            row[field] = { value: workItem.fields[columnSource.questionId]?.value, formattedValue: workItem.fields[columnSource.questionId]?.valueFormatted };
          }
      }
      rows.push(row);
  }

    return {rows, columns, columnInitialVisibility, autosizeOptions};
}

export const TableViewPaginationModes = {
  client: "client",
  server: "server",
}

export const TableViewPaginationStyles = {
  infiniteScroll: "infiniteScroll",
  navigation: "navigation",
}

const TableView = forwardRef(({
  selectorId,
  workItems,
  columnsConfig,
  classes,
  loading,
  filterMode = EXECUTION_FILTER_MODE.server.id,
  sortingMode = EXECUTION_FILTER_MODE.server.id,
  sortModel,
  filterModel,
  onSortChanged,
  onFilterChanged,
  onNextPage,
  showToolbar,
  pagination,
  expand = true,
  autoHeight,
  paginationMode = TableViewPaginationModes.client,
  paginationStyle = TableViewPaginationStyles.navigation,
  events = {
    itemClicked: REPORT_EVENT_TYPES.listingPageItemClicked.name
  }
}, outerRef) => {
  const patch = useCallbackPatchNode();

  const apiRef = useRef();

  useImperativeHandle(outerRef, () => apiRef.current);

    const history = useHistory()
    const {columns, rows, columnInitialVisibility, autosizeOptions} = useMemo(() => {
        return buildColumnsAndRows(workItems, columnsConfig, filterMode, expand, selectorId, events);
    }, [workItems, columnsConfig, filterMode, expand, selectorId, events])

    // const scrollPosition = useSelector(getScrollPosition(selectorId))
    const selector = useNodeOrNull(selectorId);

    const reportEvent = useReportEventCallback();

    const {width: windowWidth} = useWindowSize();


    const handleAutosizeColumns = () => {
      if (expand) {
        apiRef.current?.autosizeColumns(autosizeOptions);
      }
    }

    const delayedAutosize = useDebouncedCallback(handleAutosizeColumns, 250, [apiRef, autosizeOptions]);

    useEffect(() => {
      handleAutosizeColumns();
    }, [rows.length]);

    useEffect(() => {
      delayedAutosize();
      return () => {
        delayedAutosize.cancel();
      }
    }, [windowWidth, delayedAutosize])

    const localsRef = useRef({scrollRestored: false, loaded: false})
    const ref = useRef();

  const restoreScroll = useDebouncedCallback(() => {
    const scroller = document.querySelector(virtualScrollerClass);
    if (scroller && selector?.listScrollTop) {
      scroller.scrollTop = selector.listScrollTop;
      localsRef.current.scrollRestored = true;
    }
  }, 0, [selector?.listScrollTop]);
  
  useEffect(() => {
    if (!localsRef.current.scrollRestored) {
      restoreScroll();
    }
    return () => {
      restoreScroll.cancel();
    }
  }, [restoreScroll]);

  useLayoutEffect(() => {
    return () => {
      const scroller = document.querySelector(virtualScrollerClass);
      if (selector && scroller) {
        patch({id: selector?.id, listScrollTop: scroller.scrollTop});
      }
    }
  }, [patch, selector?.id]);

  const [paginationModel, setPaginationModel] = useState({
    pageSize: !isNullOrUndefined(selector?.viewingPageSize) ? selector.viewingPageSize : 10,
    page: !isNullOrUndefined(selector?.viewingPageNumber) ? selector.viewingPageNumber - 1 : 0,
  });

  const onSortModelChange = useCallback((sortModel) => {
    let nodeUpdate = {id: selectorId};
    onSortChanged?.(sortingMode)
    {
      // Reset client paging
      setPaginationModel({...paginationModel, page: 0});
      nodeUpdate.viewingPageNumber = 1;
    }
    if(sortingMode !== 'server') {
      patch({id: selectorId, sortModel})
      return;
    }
    if(isEmpty(sortModel)) {
      nodeUpdate = { ...nodeUpdate, orderBy: null, orderByDirection: null, sortModel: null }
      patch(nodeUpdate);
    } else {
      const { field, sort } = sortModel[0]
      const column = columns.find((c) => c.field === field)
      if (column) {
        const orderByDirection = sort === 'asc' ? 'ascending' : 'descending'
        nodeUpdate = { ...nodeUpdate, orderBy: column.orderBy || field, orderByDirection: orderByDirection, sortModel }
        patch(nodeUpdate);
      }
    }
  }, [columns, patch, selectorId, sortingMode, onSortChanged]);

  const onFilterChange = useCallback((filterModel) => {
    let nodeUpdate = {id: selectorId}
    onFilterChanged?.(filterModel)
    {
      // Reset client paging
      setPaginationModel({...paginationModel, page: 0});
      nodeUpdate.viewingPageNumber = 1;
    }
    if(filterMode !== 'server') {
      patch({id: selectorId, filterModel})
      return
    }
    nodeUpdate = { ...nodeUpdate, filterModel, additionalFilter: dataGridFilterToJsonLogic(columnsConfig, filterModel) }
    patch(nodeUpdate);
  }, [selectorId, columnsConfig, filterMode, onFilterChanged])

  const onColumnsChange = useCallback((visibilityModel) => {
    patch({id: selectorId, columnsVisibility: visibilityModel});
  }, [selectorId])


  const onRowClick = useCallback((params, event) => {
    const { row } = params
    const newSelectedRowIds = [];
    if (event.ctrlKey && selector?.selectedRowIds) {
      newSelectedRowIds.push(...selector?.selectedRowIds);
    }
    const currentRowIndex = newSelectedRowIds.indexOf(row.id);
    if (currentRowIndex !== -1) {
      newSelectedRowIds.splice(currentRowIndex, 1);
    } else {
      newSelectedRowIds.push(row.id);
    }
    let update = {id: selectorId, selectedRowIds: newSelectedRowIds};
    // If adding selecting new row and new rows will exceed 1
    // Turn on checkbox selection
    const hasAvailableActions = Object.keys(selector?.availableActions ?? {}).length > 0;
    if (currentRowIndex === -1 && newSelectedRowIds.length > 1 && hasAvailableActions) {
      update.checkboxSelection = true;
    }
    patch(update);
    if (event.target.classList.contains(cellClassName) && (!event.ctrlKey && event.type === 'click') || (event.type === 'keyup' && (event.keyCode === 13 || event.keyCode === 32))) {
      const {rootId, key} = params.row;
      reportEvent(selector?.procedureId, events.itemClicked, {executionId: rootId, executionKey: key});
      setTimeout(() => history.push(`/executions/${row.rootId}`), 200);
    }
    event.stopPropagation();
  }, [patch, selectorId, selector?.availableActions, selector?.selectedRowIds]);

  const onRowSelectionChange = (newSelectedRowIds) => {
    patch({id: selectorId, selectedRowIds: newSelectedRowIds});
  }

  const tableKey = `${selectorId}-${selector?.selectedViewId}`;
  useEffect(() => {

      if(!ref.current) {
          return;
      }

      const isolateTouch = e => e.stopPropagation();

      ref.current.addEventListener('touchstart', isolateTouch, {passive: true});
      ref.current.addEventListener('touchmove', isolateTouch, {passive: true});
      ref.current.addEventListener('touchend', isolateTouch, {passive: true});

      return () => {

          if(!ref.current) {
              return;
          }

          ref.current.removeEventListener('touchstart', isolateTouch, {passive: true});
          ref.current.removeEventListener('touchmove', isolateTouch, {passive: true});
          ref.current.removeEventListener('touchend', isolateTouch, {passive: true});
      }

  },[ref.current]);

  const handleRowsScrollEnd = () => {
    if (paginationStyle === TableViewPaginationStyles.infiniteScroll) {
      const visibleColumns = apiRef.current?.getVisibleColumns() ?? [];
      if (visibleColumns.length) {
        onNextPage?.();
      }
    }
  }

  const onPaginationModelChange = (newPageModel) => {
    const useNewPage = newPageModel.page + 1;
    // New page is next to current page
    if (paginationMode === TableViewPaginationModes.server &&
        paginationStyle === TableViewPaginationStyles.navigation &&
        paginationModel.page + 1 === newPageModel.page) {
      onNextPage?.(newPageModel.page);
    }
    setPaginationModel(newPageModel);
    patch({id: selectorId, viewingPageNumber: useNewPage, viewingPageSize: newPageModel.pageSize});
  }

  const rowCountRef = useRef(selector?.total || 0);

  const rowCount = useMemo(() => {
    if (selector?.total !== undefined) {
      rowCountRef.current = selector?.total;
    }

    return rowCountRef.current;
  }, [selector?.total]);

  let paginationProps = {};

  if (pagination && paginationStyle === TableViewPaginationStyles.navigation) {
    const usePagination = pagination && workItems.length >= 10
    paginationProps = {
      paginationModel,
      onPaginationModelChange,
      pageSizeOptions: [10, 25, 100],
      pagination: usePagination,
      hideFooter: !usePagination,
    }
    if (paginationMode === TableViewPaginationModes.server) {
      paginationProps = {
        ...paginationProps,
        rowCount,
        pageSizeOptions: [10, 25, 100],
        pagination: true,
        paginationMode: "server",
        // When rows are cached, MUI pagination renders all available rows in spite of page size
        rows: rows.slice(paginationModel.page * paginationModel.pageSize, (paginationModel.page * paginationModel.pageSize) + paginationModel.pageSize)
      }
    }
  }

  const onBulkActionsClose = () => {
    patch({id: selectorId, selectedRowIds: []})
  }

  const hasAvailableActions =  Object.keys(selector?.availableActions ?? {}).length > 0;
  const allowExport = paginationMode === TableViewPaginationModes.client || workItems.length === selector?.total;

  const handlePanelOpened = (params) => {
    const panel = params.openedPanelValue;
    let eventName;
    switch (panel) {
      case "filters":
        eventName = REPORT_EVENT_TYPES.listingPageFilterOpened.name;
        break;
      case "columns":
        eventName = REPORT_EVENT_TYPES.listingPageColumnsOpened.name;
        break;
    }
    if (eventName) {
      reportEvent(selector?.procedureId, eventName);
    }
  }

  return (
    <div className={cn('materialTableResponsiveWrapper', 'textFieldPrimary', classes.root)} ref={ref}>
      <GridBulkActions selectorId={selectorId} selectedRowIds={selector?.selectedRowIds ?? []} onClose={onBulkActionsClose} allowActions={!!selector?.checkboxSelection && hasAvailableActions} />
      <DataGridPremium
        apiRef={apiRef}
        loading={loading}
        key={tableKey}
        columns={columns}
        rows={rows}
        filterMode={filterMode}
        sortingMode={sortingMode}
        filterModel={filterModel}
        sortModel={sortModel ?? []} // to fix BUG-3674
        onSortModelChange={onSortModelChange}
        onFilterModelChange={onFilterChange}
        onColumnVisibilityModelChange={onColumnsChange}
        onRowSelectionModelChange={onRowSelectionChange}
        getRowClassName={() => classes.rowWrapper}
        onRowClick={onRowClick}
        hideFooter
        getRowHeight={() => 'auto'}
        disableRowGrouping
        disableAggregation
        disableVirtualization
        rowSelectionModel={selector?.selectedRowIds}
        data-table-selector={selectorId}
        slotProps={{
          toolbar: {allowExport}
        }}
        slots={{
          noRowsOverlay: EmptyContent,
          toolbar: showToolbar ? Toolbar: undefined,
          row: RowRenderer
        }}
        initialState={{
          columns: {
            columnVisibilityModel: columnInitialVisibility
          }
        }}
        hideFooterSelectedRowCount
        autoHeight={autoHeight}
        autosizeOptions={expand ? autosizeOptions : null}
        autosizeOnMount={expand}
        onRowsScrollEnd={handleRowsScrollEnd}
        scrollEndThreshold={200}
        checkboxSelection={selector?.checkboxSelection}
        onPreferencePanelOpen={handlePanelOpened}
        {...paginationProps}
      />
    </div>
  )
})


const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    height: '100%',
    "& .MuiTablePagination-root": {
      "& .MuiTablePagination-select": {
        height: "auto !important",
        border: "none !important",
      },
      "& .MuiSvgIcon-root.MuiSelect-icon": {
        position: "absolute",
        right: "0 !important",
        top: "calc(50% - .5em) !important",
      },
      "& p": {
        margin: "auto !important",
      }
    },
  },
  rowWrapper: {
    cursor: 'pointer',
    transition: 'background-color 0.15s ease-in-out',
    '&:hover': {
      boxShadow: '0 3px 3px #00000029',
      backgroundColor: `${theme.palette.grey.one.main}`,
    },
    '&:focus': {
      outline: 'auto',
    }
  }
})


export default (withStyles(styles)(TableView));
