
import React, {useImperativeHandle, useRef, forwardRef, useMemo, useState, useEffect} from "react";
import {
    DataGridPremium as DataGrid,
    GridToolbarContainer,
    GridToolbarQuickFilter,
} from "@mui/x-data-grid-premium";
import {LicenseInfo} from '@mui/x-license';
import { strings } from "../SopLocalizedStrings";
import makeStyles from "@mui/styles/makeStyles";
import Tooltip from "@mui/material/Tooltip";
import { IconButton } from "@mui/material";
import HistoryIcon from '@mui/icons-material/HistoryRounded';
import UpdateIcon from '@mui/icons-material/UpdateRounded';
import cn from "classnames";

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUIX_LICENCE);

const useStyle = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        height: "100%",
    },
    total: {
        padding : "5px 0",
    },
    count: {
        color: theme.palette.grey.seven.main,
        fontSize: 16,
        fontFamily: theme.fonts.primary.bold,
        marginRight: 5,
    },
    toolbar: {
        padding: "5px 14px",
        justifyContent: "space-between",
    },
    toolbarFilters: {
        display: "flex",
        columnGap: "8px",
    },
    deletedRow: {
        backgroundColor: `${theme.palette.deleted.one.main} !important`,
    }
}));



function CustomToolbar ({total, additionalFilters, showDeleted, allowToggleDeleted, onToggleDeleted, ...props}) {

    const classes = useStyle();

    return (
        <GridToolbarContainer className={classes.toolbar} {...props}>
            <div className={classes.toolbarFilters}>
                <div className={classes.total}>
                    <label className={classes.count}>{strings.dataTable.title}</label>
                    <span data-cy={'count'} className={'muiToolbarCountBadge'}>{total}</span>
                </div>
                {additionalFilters}
            </div>
            <div>
                <GridToolbarQuickFilter data-cy="grid-quick-filter" />
                {allowToggleDeleted && <Tooltip title={strings.dataTable.toggleShowDeleted}>
                    <IconButton
                        onClick={onToggleDeleted}
                    >
                        {showDeleted ? <UpdateIcon /> : <HistoryIcon />}
                    </IconButton>
                </Tooltip>}
            </div>
        </GridToolbarContainer>
    );
}

const useCustomGridData = (rows, columns, showDeleted = false) => {
    return useMemo(() => {
        columns.forEach((c) => {
            c.flex = 1;
            c.minWidth = 100;
        });
        rows = rows.filter(r => showDeleted ? r.deleted : !r.deleted);
        return {rows, columns};
    }, [rows, columns, showDeleted]);
}

/**
 * Common data grid format across the app.
 * Toggling for deleted is handled here, make sure to keep the deleted prop in row data.
 */
const CustomDataGrid = forwardRef(({className, onToggleDeleted, additionalFilters, rows, columns, allowToggleDeleted = true, ...props}, ref) => {

    const classes = useStyle();

    const [showDeleted, setShowDeleted] = useState(props.displayDeleted ?? false);

    const apiRef = useRef();
    useImperativeHandle(ref, () => apiRef.current);

    const data = useCustomGridData(rows, columns, showDeleted);

    const handleToggleDeleted = () => {
        setShowDeleted(!showDeleted);
        onToggleDeleted?.(!showDeleted);
    }

    const getRowClassName = ({row}) => {
        return cn({[classes.deletedRow]: row.deleted});
    }

    return <div className={classes.root}>
        <DataGrid
            {...data}
            className={cn(className)}
            apiRef={apiRef}
            slots={{
                toolbar: CustomToolbar,
            }}
            slotProps={{
                toolbar: {
                    total: data.rows.length,
                    additionalFilters,
                    allowToggleDeleted,
                    showDeleted,
                    onToggleDeleted: handleToggleDeleted,
                }
            }}
            getRowClassName={getRowClassName}
            hideFooter
            disableRowGrouping
            disableAggregation
            {...props}
        />
    </div>;
})

export default CustomDataGrid;