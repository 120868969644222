import { tbfGlobalStyles } from 'tbf-react-library';

export const styles = theme => ({
    ...tbfGlobalStyles(theme),
    footerColor: {
        color: 'rgb(0 0 0 / 65%)',
        background: '#FFF',
        padding: '10px 20px'
    },
    rootOverride: {
        overflow: "unset !important",
    }
});