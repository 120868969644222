import React from "react";
import withStyles from '@mui/styles/withStyles';
import {strings} from "../../components/SopLocalizedStrings";
import {Grid} from "@mui/material";
import {Panel} from "tbf-react-library";
import ProcedureNavigationStyle from "../design/ProcedureNavigationStyle";
import ProcedureCompleteActionStyle from "../design/ProcedureCompleteActionStyle";
import ProcedureAddDialogNavigationMode from "../design/ProcedureAddDialogNavigationMode";
import ProcedureAddMoreAvailableToggle from "../design/ProcedureAddMoreAvailableToggle";
import TbfColorPicker from "../../components/common/TbfColorPicker";
import ProcedureThemeColorPicker from "../design/ProcedureThemeColorPicker";
import ProcedureThemeIconPicker from "../design/ProcedureThemeIconPicker";
import ProcedureGlobalNavigationStyle from "../design/ProcedureGlobalNavigationStyle";

const pageStrings = strings.procedure.settings.design;

const ProcedureSettingsDesign = ({nodeId, disabled, showDeleted}) => {
    return (<>
        <Panel title={pageStrings.title}>

            <Grid container>
                <Grid item xs={12}>
                    <ProcedureNavigationStyle
                        procedureId={nodeId}
                        disabled={disabled}
                        showDeleted={showDeleted}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ProcedureGlobalNavigationStyle
                        procedureId={nodeId}
                        disabled={disabled}
                        showDeleted={showDeleted}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ProcedureCompleteActionStyle
                        procedureId={nodeId}
                        disabled={disabled}
                        showDeleted={showDeleted}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ProcedureAddDialogNavigationMode
                        procedureId={nodeId}
                        disabled={disabled}
                        showDeleted={showDeleted}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ProcedureAddMoreAvailableToggle
                        procedureId={nodeId}
                        disabled={disabled}
                        showDeleted={showDeleted}
                    />
                </Grid>
            </Grid>
        </Panel>
        <Panel title={pageStrings.theme}>
            <Grid container>
                <Grid item xs={12}>
                    <ProcedureThemeColorPicker procedureId={nodeId} disabled={disabled} />
                </Grid>
                <Grid item xs={12}>
                    <ProcedureThemeIconPicker procedureId={nodeId} disabled={disabled} />
                </Grid>
            </Grid>
        </Panel>
    </>);
}
const styles = () => ({});
export default withStyles(styles)(ProcedureSettingsDesign);
